import MindType from "../../../../viewmodel/datatype/MindType"
import MindDisplayType from "../../../../viewmodel/datatype/MindDisplayType"
import NodeLayoutType from "../../../../viewmodel/datatype/NodeLayoutType"
import LineLayout from "../../../../viewmodel/datatype/LineLayout"
import MindElementType from "../../../../viewmodel/datatype/MindElementType"
import MindElementShapeType from "../../../../viewmodel/datatype/MindElementShapeType"
import LineMindTypeNodeUnit from "../../../../viewmodel/mindelementdata/LineMindTypeNodeUnit"
import MindElementCalculation from "../../calcule/elementCalculation/MindElementCalculation"
import MindDateFormat from "../MindDateFormat"
import Colors from "../../../../utils/Colors"
import LineMindTypeNode from "../../../../viewmodel/mindelementdata/LineMindTypeNode"
import MindBGStripesType from "../../../../viewmodel/datatype/MindBGStripesType"
if (
    typeof navigator === "object" &&
    typeof navigator.userAgent === "string" &&
    navigator.userAgent.indexOf("Electron") >= 0
) {
    var { ipcRenderer } = window.require("electron");

}
import {
    postMindmapNew,
} from "../../../../common/netWork/mind_map_api";
import Config from "../../calcule/Config"
import Strings from "../../../../utils/Strings"
import IdGenerator from "../../../../viewmodel/core/base/IdGenerator"
import SettingData from "../../../../viewmodel/core/minddata/SettingData"
import MindElementAlignmentType from "../../../../viewmodel/datatype/MindElementAlignmentType"

class TxtFileImport {
    constructor(delegate, value) {
        this.delegate = delegate;
        this.value = value;
        this.lineSeparatorByN = "\n";
        this.lineSeparatorByR = "\r";
        this.lineSeparatorByRN = "\r\n";
        this.lineSeparatorByNR = "\n\r";

        this.lineIndentByT = "\t";
        this.lineIndentByXmind = "#";
        this.lineIndentByXingmind = "*";
        this.lineIndentByArrow = "> ";
        this.lineIndentBySpace = "  ";
        this.lineIndentBySpace2 = "　　";

        this.lineIndentByTitleForMarkdown = "__";

        this.title = "";
        this.dataList = new Array();
        this.mainMindNodeUnit;
        this.mindType = MindType.LINE_MAP;
        this.mindDisplayType = MindDisplayType.MindMap;
        this.layout = NodeLayoutType.LAYOUT_LEFT_RIGHT;
        this.lineLayout = LineLayout.CURVE_LINE;
        this.mindElementShape = MindElementShapeType.Semicircle;
        this.mindSubElementShape = MindElementShapeType.CornerShadow;
        this.name = "";
        this.subtitle = "";
        this.baseLevel = 0;
        this.mindBGColor = Colors.white;
        this.settingData = new SettingData();
        this.initData();
    }

    initData() {
        IdGenerator.shared.resetId()
        this.mainMindNodeUnit = new LineMindTypeNodeUnit(this.mindBGColor, MindType.LINE_MAP, 25, false);
        this.mainMindNodeUnit.setMindBGColor(this.mainMindNodeUnit.mindMapStyleColor.getMindMapFillColor());
        this.mainMindNodeUnit.setMindDisplayType(this.mindDisplayType);
        this.mainMindNodeUnit.setElementLayout(this.layout, true);
        this.mainMindNodeUnit.globalLineLayout = this.lineLayout;
        this.mainMindNodeUnit.createRootNode();
    }

    format() {
        this.dataSeparator();
        let nodeCount = this.dataList.length;
        if (nodeCount < 0 || this.mainMindNodeUnit.rootTreeNode == null) {
            return;
        }
        
        this.creatNode();
        this.setMapStyle();
        this.mainMindNodeUnit.refreshLayout(false, new SettingData());
        this.save();
    }

    getTextContent() {
        this.dataSeparator()
        let nodeCount = this.dataList.count
        if (nodeCount < 0) {
            return ""
        }

        var text = "";
        for (let index = 0; index < nodeCount; index++) {
            let value = this.dataList[index];
            if (value == null || value.length == 0) {
                continue;
            }
            text += (text.length == 0 ?  value : this.lineSeparatorByN + value);
        }
        return text;
    }

    setMapStyle() {
        if (this.mainMindNodeUnit.rootTreeNode == null || this.mainMindNodeUnit.rootTreeNode.isEmpty()) {
            return;
        }
        if (this.mainMindNodeUnit.rootTreeNode.children.length > 10) {
            this.layout = NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE;
            this.mainMindNodeUnit.setElementLayout(this.layout, true);
            this.mainMindNodeUnit.refreshLayout(false, this.settingData);
            this.mainMindNodeUnit.setFramework(4, this.settingData);
        } else if (this.mainMindNodeUnit.rootTreeNode.children.length > 4) {
            this.layout = NodeLayoutType.LAYOUT_RIGHT;
            this.mainMindNodeUnit.setElementLayout(this.layout, true);
            this.mainMindNodeUnit.refreshLayout(false, this.settingData);
            this.mainMindNodeUnit.setFramework(7, this.settingData);
        } else {
            this.layout = NodeLayoutType.LAYOUT_BRACKETS_RIGHT;
            this.mainMindNodeUnit.setElementLayout(this.layout, true);
            this.mainMindNodeUnit.refreshLayout(false, this.settingData);
            this.mainMindNodeUnit.setFramework(9, this.settingData);
        }

        if (this.mainMindNodeUnit.rootTreeNode.children.length > 2) {
            let rainbowColoresIndex = Math.min(Math.ceil(Math.random() * (Colors.RainbowColores.length - 1)), Colors.RainbowColores.length - 1);
            let lineColors = Colors.RainbowColores[rainbowColoresIndex];
            if (lineColors.length > 0) {
                this.settingData.lineColor = []
                for (let index = 1; index < lineColors.length; index++) {
                    this.settingData.lineColor.push(lineColors[index]);
                }
            }
            this.mainMindNodeUnit.onSelectedRainbowr(lineColors);
        }
        this.mainMindNodeUnit.rootTreeNode.value.alignmentType = MindElementAlignmentType.MIDDLE;
    }

    save() {
        let mindDateFormat = new MindDateFormat();
        mindDateFormat.mindBGColor = Colors.white;
        mindDateFormat.mindDisplayType = this.mindDisplayType;
        mindDateFormat.styleIndex = 3;
        mindDateFormat.version = Config.MindFormatVersion;
        mindDateFormat.mindGlobalAudio = "";
        mindDateFormat.mindBGStripesType = MindBGStripesType.NonExistent;
        mindDateFormat.settingData = this.settingData

        let freeNodes = new Array();
        mindDateFormat = mindDateFormat.setDatas(
            this.mainMindNodeUnit.rootTreeNode,
            new Array(),
            new Array(),
            new Array(),
            freeNodes,
            new Array())
            .setLayout(this.layout, this.lineLayout);
        let mindNodeJson = mindDateFormat.toJSONString();
        let mindTitle = new Strings().isEmpty(this.name) ? "MindMap" : this.name;
        IdGenerator.shared.resetId()
        if (mindNodeJson != null) { //创建导图到服务器，content= mindNodeJson, ...根据服务器返回，跳转进入导图
            const obj = {
                parentEntityId: '',
                name: mindTitle,
                content: mindNodeJson,
                type: this.mindType,
                cover: '',
                subtitle: '',
            }
            postMindmapNew(
                obj,
                (res) => {
                    // this.delegate.$message.success(getString(strings.Message_Tips_Create_Success));
                    let mindId = res.id;
                    if (this.delegate.$tools.isElectron()) {
                        ipcRenderer.send("openCalendarWindow", "/mindmap?id=" + mindId);
                    } else {
                        const routerData = this.delegate.$router.resolve({
                            path: "/mindmap",
                            query: {
                                id: mindId,
                            },
                        });
                        this.createATagJump(routerData.href);
                    }
                },
                (error) => {
                    this.delegate.$message.error(error.desc);
                }
            );
            this.delegate.reload();
            this.delegate.showModal = false;//隐藏导入文件弹窗
        }
    }

    createATagJump(url) {
        const a = document.createElement('a');//创建a标签浏览器不会阻止弹出窗口
        a.setAttribute('href', url);
        a.setAttribute('target', '_blank');
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
            document.body.removeChild(a);
        }, 5000);
    }

    creatNode() {
        let nodeCount = this.dataList.length;
        let mainMindNodeUnit = this.mainMindNodeUnit;
        if (mainMindNodeUnit.rootTreeNode == null) {
            return;
        }
        let rootData = mainMindNodeUnit.rootTreeNode;
        let dataListFirstElement = this.dataList[0];
        let rootInfo = this.getNodeLevel(dataListFirstElement);
        if (rootInfo.text.length == 0) {
            rootData.value.textContent.text = dataListFirstElement;
        } else {
            rootData.value.textContent.text = rootInfo.text;
            this.baseLevel = rootInfo.level;
        }

        rootData.value.mindElementShape = this.mindElementShape;
        this.cutTerms(rootData);
        MindElementCalculation.set(rootData.value).caluleTextForData();
        if (dataListFirstElement.length > 14) {
            this.name = this.removeSpecialTagForDisplay(dataListFirstElement.substring(0, 14));
        } else {
            this.name = this.removeSpecialTagForDisplay(dataListFirstElement);
        }

        if (nodeCount == 1) {
            return;
        }
        let perviewNode = mainMindNodeUnit.rootTreeNode;
        let textPerviewLevel = 0;
        for (let index = 1; index < nodeCount; index++) {
            let text = this.dataList[index];
            if (text.length > Config.Number_Imported_File_Node_Characters) {
                text = text.substr(0,Config.Number_Imported_File_Node_Characters);
            }
            let info = this.getNodeLevel(text);
            if (info.text.length == 0) {
                continue;
            }
            text = info.text;
            let isTitle = this.isTitleForMarkdown(text)
            let level = info.level - this.baseLevel;
            text = this.removeSpecialTagForDisplay(text)
            let newNode;

            if (level == 0) {
                newNode = mainMindNodeUnit.addChildMindElement(mainMindNodeUnit.rootTreeNode.value.id);
                newNode.value.textContent.text = text;
                perviewNode = newNode;
            } else if (perviewNode.value.level < level || textPerviewLevel < level) {
                newNode = mainMindNodeUnit.addChildMindElement(perviewNode.value.id);
                newNode.value.textContent.text = text;
                perviewNode = newNode;
            } else if (perviewNode.value.level == level || textPerviewLevel == level) {
                let parentNode = mainMindNodeUnit.getNodeById(perviewNode.value.parentNodeId);
                if (parentNode.isEmpty()) {
                    newNode = mainMindNodeUnit.addChildMindElement(mainMindNodeUnit.rootTreeNode.value.id);
                    newNode.value.textContent.text = text;
                    perviewNode = newNode;
                } else {
                    newNode = mainMindNodeUnit.addChildMindElement(parentNode.value.id);
                    newNode.value.textContent.text = text;
                    perviewNode = newNode;
                }
            } else {
                let parentNode = this.getNodeByLevel(perviewNode, level - 1);
                if (parentNode.isEmpty()) {
                    newNode = mainMindNodeUnit.addChildMindElement(mainMindNodeUnit.rootTreeNode.value.id);
                    newNode.value.textContent.text = text;
                    perviewNode = newNode;
                } else {
                    newNode = mainMindNodeUnit.addChildMindElement(parentNode.value.id);
                    newNode.value.textContent.text = text;
                    perviewNode = newNode;
                }
            }
            textPerviewLevel = level;
            if (newNode != null) {
                if (newNode.value.type == MindElementType.SUBJECT) {
                    newNode.value.mindElementShape = this.mindSubElementShape;
                }
                this.cutTerms(newNode);
                MindElementCalculation.set(newNode.value).caluleTextForData();
            }
        }
        if (rootData.children.length < 4) {
            this.layout = NodeLayoutType.LAYOUT_RIGHT;
        } else {
            this.layout = NodeLayoutType.LAYOUT_LEFT_RIGHT;
        }
    }
    cutTerms(node) {
        if (node.isEmpty() || node.value.textContent == null || node.value.textContent.text.length < 5) {
            return;
        }

        MindElementCalculation.set(node.value).caluleTextForData()
        let lineWidth = node.value.textContent.width;
        if ((node.value.type == MindElementType.MAIN_SUBJECT || node.value.type == MindElementType.SUBJECT) && lineWidth < 200) {
            return;
        } else if (node.value.type == MindElementType.SON_SUBJECT && lineWidth < 500) {
            return;
        }

        let text = node.value.textContent.text;
        let txtCount = text.length;

        let lineNum = this.caluleTextLineNum(node, (Math.ceil((txtCount) / 2)));
        let line1 = text.substring(0, lineNum);
        let line2 = text.substring(lineNum);

        text = line1 + this.lineSeparatorByN + line2;
        text = this.removeSpecialTagForDisplay(text)
        node.value.textContent.text = text;
    }

    caluleTextLineNum(node, lineNum) {
        let text = node.value.textContent.text;
        if (lineNum > text.length - 1) {
            return lineNum;
        }
        let line1 = text.substring(0, lineNum);
        let line2 = text.substring(lineNum);

        node.value.textContent.text = line1;
        MindElementCalculation.set(node.value).caluleTextForData();
        let line1Width = node.value.textContent.width;
        node.value.textContent.text = line2;
        MindElementCalculation.set(node.value).caluleTextForData();
        let line2Width = node.value.textContent.width;

        node.value.textContent.text = text;

        if (line1Width < line2Width) {
            return this.caluleTextLineNum(node, lineNum + 1);
        } else {
            return lineNum;
        }
    }

    getNodeLevel(text, base = 0) {
        if (text.startsWith(this.lineIndentByArrow)) {
            let index = this.lineIndentByArrow.indexOf(this.lineIndentByArrow);
            if (index == -1) {
                return new NodeLevelData(text, base);
            }
            let contentText = text.replace(this.lineIndentByArrow, "");
            return this.getNodeLevel(contentText, base + 1);
        } else if (text.startsWith(this.lineIndentByT)) {
            let index = this.lineIndentByT.indexOf(this.lineIndentByT);
            if (index == -1) {
                return new NodeLevelData(text, base);
            }

            let contentText = text.replace(this.lineIndentByT, "");
            return this.getNodeLevel(contentText, base + 1);
        } else if (text.startsWith(this.lineIndentByXmind)) {
            let index = this.lineIndentByXmind.indexOf(this.lineIndentByXmind);
            if (index == -1) {
                return new NodeLevelData(text, base);
            }
            let contentText = text.replace(this.lineIndentByXmind, "");
            return this.getNodeLevel(contentText, base + 1);
        } else if (text.startsWith(this.lineIndentByXingmind)) {
            let index = this.lineIndentByXingmind.indexOf(this.lineIndentByXingmind);
            if (index == -1) {
                return new NodeLevelData(text, base);
            }
            let contentText = text.replace(this.lineIndentByXingmind, "");
            return this.getNodeLevel(contentText, base + 1);
        } else if (text.startsWith(this.lineIndentBySpace)) {
            let index = this.lineIndentBySpace.indexOf(this.lineIndentBySpace);
            if (index == -1) {
                return new NodeLevelData(text, base);
            }
            let contentText = text.replace(this.lineIndentBySpace, "");
            return this.getNodeLevel(contentText, base + 1);
        } else if (text.startsWith(this.lineIndentBySpace2)) {
            let index = this.lineIndentBySpace2.indexOf(this.lineIndentBySpace2);
            if (index == -1) {
                return new NodeLevelData(text, base);
            }
            let contentText = text.replace(this.lineIndentBySpace2, "");
            return this.getNodeLevel(contentText, base + 1);
        }

        return new NodeLevelData(text, base);
    }

    dataSeparator() {
        let separator = this.lineSeparatorByN;
        if (this.value.includes(this.lineSeparatorByRN)) {
            separator = this.lineSeparatorByRN;
        } else if (this.value.includes(this.lineSeparatorByNR)) {
            separator = this.lineSeparatorByNR;
        } else if (this.value.includes(this.lineSeparatorByN)) {
            separator = this.lineSeparatorByN;
        } else if (this.value.includes(this.lineSeparatorByR)) {
            separator = this.lineSeparatorByR;
        }

        let parts = this.value.split(separator);
        let count = parts.length;
        if (count > 0) {
            for (let index = 0; index < count; index++) {
                if (parts[index].trim().length > 0) {
                    this.dataList.push(parts[index]);
                }
            }
        } else {
            this.dataList.push(this.value);
        }
        if (this.dataList.length > Config.Number_Imported_File_Nodes) {
            this.dataList.length = Config.Number_Imported_File_Nodes;
        }
    }

    getNodeByLevel(node, level) {
        if (node.value.level == level) {
            return node;
        } else {
            let parentNode = this.mainMindNodeUnit.getNodeById(node.value.parentNodeId);
            if (!parentNode.isEmpty()) {
                return this.getNodeByLevel(parentNode, level);
            } else {
                return new LineMindTypeNode();
            }
        }
    }

    removeSpecialTagForDisplay(text) {
        if (text.endsWith(this.lineIndentByXingmind)) {
            text = text.substr(0, text.length - this.lineIndentByXingmind.length)
        }
        if (text.startsWith(this.lineIndentByTitleForMarkdown) &&
            text.endsWith(this.lineIndentByTitleForMarkdown)) {
            text = text.substr(this.lineIndentByTitleForMarkdown.length, text.length - this.lineIndentByTitleForMarkdown.length)
            text = text.substr(0, text.length - this.lineIndentByTitleForMarkdown.length)
        }
        return text
    }

    isTitleForMarkdown(text) {
        if (text.startsWith(this.lineIndentByTitleForMarkdown) &&
            text.endsWith(this.lineIndentByTitleForMarkdown)) {
            return true
        }
        return false
    }
}

class NodeLevelData {
    constructor(text, level) {
        this.text = text;
        this.level = level;
    }
}

export default TxtFileImport