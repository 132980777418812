<template>
  <div id="my-colletion-newfile">
    <div class="ct-newfile-box">
      <div class="ct-newfile-header">
        <span class="newfile-text" v-if="newFileData.style == 'newProFile'"
          >{{getString(strings.User_New_Group_Title)}}</span
        >
        <span class="newfile-text" v-else>{{getString(strings.User_Folder_Rename)}}</span>
        <span class="newfile-close-icon" @click="closeNewFileModel">
          <!-- <a-icon type="close" :style="{ fontSize: '12px' }" /> -->
          <img class="close-button-img" 
            src="../../assets/img/popupsImg/colse_new.svg"  
             />
        </span>
      </div>

      <div class="ct-newfile-form">
        <a-form :form="form" @submit="newFileToMyColletion">
          <a-form-item :label="getString(strings.Mind_Mindmap_Deleted_Filename)+'：'">
            <a-input
              v-decorator="[
                'fillname',
                {
                  rules: [{ required: true, message: '文件夹名称不能为空' }],
                },
              ]"
            >
            </a-input>
          </a-form-item>
          <!-- 修改权限 -->
          <div
            class="ct-filelimit-form"
            v-if="newFileData.style == 'newProFile'"
          >
            <div class="ct-filelimit-title">{{getString(strings.User_Folder_Rename_Root_Title)}}</div>
            <a-radio-group v-model="value" @change="onChange">
              <div class="ct-public ct-box">
                <div class="ct-public-title">
                  <span class="ct-titel">{{getString(strings.User_Folder_Root_Public)}}</span>
                  <span class="ct-content"
                    >{{getString(strings.User_Folder_Root_Public_Introduction)}}</span
                  >
                </div>
                <div class="ct-public-radio">
                  <a-radio :value="1"></a-radio>
                </div>
              </div>
              <div class="ct-public-look ct-box">
                <div class="ct-public">
                  <div class="ct-public-title">
                    <span class="ct-titel">{{getString(strings.User_Folder_Root_Viewonly)}}</span>
                  </div>
                  <div class="ct-public-radio">
                    <a-radio :value="2"></a-radio>
                  </div>
                </div>
              </div>
              <div class="ct-private ct-box">
                <div class="ct-public">
                  <div class="ct-public-title">
                    <span class="ct-titel">{{getString(strings.User_Folder_Root_Private)}}</span>
                  </div>
                  <div class="ct-public-radio">
                    <a-radio :value="3"></a-radio>
                  </div>
                </div>
              </div>
            </a-radio-group>
          </div>
          <a-form-item class="newfile-submit">
            <a-button @click="closeNewFileModel">{{getString(strings.Global_Cancel)}}</a-button>
            <a-button type="primary" html-type="submit"> {{getString(strings.Global_Ok)}} </a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import {
  postUserNewCollection,
  postUserRenameCollection,
} from "../../common/netWork/base_api";

import strings from "../../common/lang/strings";
import getString from "../../common/lang/language";

export default {
  data() {
    return {
      form: this.$form.createForm(this, { name: "coordinated" }),
      value: 1,
      isPrivate: false,
      canBeSaved: true,
      strings: strings,
    };
  },
  computed: {
    ...mapState({
      newFileData: (state) => state.colletionNewFileModel.data,
    }),
  },
  mounted() {
    if (this.newFileData.style == "updataName") {
      this.form.setFieldsValue({
        fillname: this.newFileData.name,
      });
    }
  },
  methods: {
    ...mapMutations(["colletionNewFileModel"]), //语言包
    getString(i) {
      return getString(i);
    },
    //关闭新建文件夹
    closeNewFileModel() {
      this.colletionNewFileModel({ accout: false });
    },
    //修改文件夹权限
    onChange(e) {
      this.value = e.target.value;
      if (e.target.value == 1) {
        this.isPrivate = false;
        this.canBeSaved = true;
      } else if (e.target.value == 2) {
        this.isPrivate = false;
        this.canBeSaved = false;
      } else if (e.target.value == 3) {
        this.isPrivate = true;
        this.canBeSaved = false;
      }
    },
    //新建文件夹
    newFileToMyColletion(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.newFileData.style == "newProFile") {
            postUserNewCollection(
              {
                type: this.newFileData.type,
                name: values.fillname,
                isPrivate: this.isPrivate,
                canBeSaved: this.canBeSaved,
              },
              (res) => {
                this.$message.success(getString(strings.Message_Tips_New_Profile_Success));
                this.closeNewFileModel();
              },
              (error) => {
                this.$message.error(error.desc);
              }
            );
          } else if (this.newFileData.style == "updataName") {
            const collectionId = this.newFileData.id;
            const name = values.fillname;
            postUserRenameCollection(
              { collectionId, name },
              (res) => {
                this.$message.success(getString(strings.Message_Tips_Modification_Success));
                this.closeNewFileModel();
              },
              (error) => {
                this.$message.error(error.desc);
              }
            );
          } else if (this.newFileData.style == "newProFileToTemplate") {
            postUserNewCollection(
              {
                type: this.newFileData.type,
                name: values.fillname,
                isPrivate: false,
                canBeSaved: true,
              },
              (res) => {
                this.$message.success(getString(strings.Message_Tips_New_Profile_Success));
                this.closeNewFileModel();
              },
              (error) => {
                this.$message.error(error.desc);
              }
            );
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
#my-colletion-newfile {
  width: 524px;
  max-height: 390px;
  min-height: 213px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 2px 2px 10px 6px rgba(0, 0, 0, 0.16);
  z-index: 2000;
  border-radius: 10px;
  .ct-newfile-box {
    // padding: 20px 20px 20px 20px;
    .ct-newfile-header {
      padding: 6px 20px;
      background: #efefef;
      border-radius: 10px 10px 0 0;
      .newfile-text {
        display: flex;
        // justify-content: center;
        font-size: 18px;
        font-family: Noto Sans SC;
        /*font-weight: bold;*/
        line-height: 24px;
        color: #333333;
        opacity: 1;
      }
      .newfile-close-icon {
        position: absolute;
        right: 20px;
        top: 7px;
        cursor: pointer;
        transition: 0.3s;
        color: #333333;
      }
      .newfile-close-icon:hover {
        transition: 0.3s;
        color: #fd492b;
        transform: rotate(360deg);
      }
    }
    .ct-newfile-form {
      margin-top: 30px;
      .ant-form {
        label {
          font-size: 16px !important;
        }
        .ant-form-item {
          display: flex;
          padding: 15px 20px;
          label::before {
            content: none !important;
            font-size: 20px;
          }
          .ant-form-item-label {
            .ant-form-item-required::before {
              display: inline-block;
              margin-right: 4px;
              color: #f5222d;
              font-size: 14px;
              font-family: SimSun, sans-serif;
              line-height: 1;
              content: none !important;
            }
          }
        }
      }
      input {
        width: 394px;
        background: #ffffff;
        border: 1px solid #e2e2e2;
        opacity: 1;
        border-radius: 2px;
      }
      .newfile-submit {
        display: flex;
        justify-content: center;
        margin-bottom: 0;
        flex-wrap: nowrap;
        button {
          width: 80px;
          height: 42px;
          opacity: 1;
          border-radius: 2px;
          margin: 0 10px;
          padding: 0 12px;
          span {
            text-align: center;
          }
        }
      }
      //修改权限
      .ct-filelimit-form {
        margin-top: -10px;
        width: 100%;
        display: flex;
        padding: 0px 20px;
        flex-wrap: wrap;
        .ct-filelimit-title {
          font-size: 16px;
          font-family: Noto Sans SC;
          font-weight: 400;
          line-height: 24px;
          color: #333333;
          opacity: 1;
        }
        .ant-radio-group {
          width: 100%;
          .ct-box {
            margin: 20px 0;
          }
          .ct-public {
            width: 100%;
            height: 22px;
            display: flex;
            justify-content: space-between;
            .ct-public-title {
              .ct-titel {
                font-size: 14px;
                font-family: Noto Sans SC;
                font-weight: 400;
                line-height: 24px;
                color: #666666;
                opacity: 1;
              }
              .ct-content {
                font-size: 12px;
                font-family: Noto Sans SC;
                font-weight: 400;
                line-height: 24px;
                color: #999999;
                opacity: 1;
                margin-left: 20px;
              }
            }
          }
          .ct-public-look {
            width: 100%;
            height: 22px;
          }
          .ct-private {
            width: 100%;
            height: 22px;
          }
        }
      }
    }
  }
}
.close-button-img{
    width:14px;
    cursor: pointer;
    opacity: 0.5;
}
.close-button-img:hover{
    opacity: 1;
}
</style>