import { render, staticRenderFns } from "./MyColletionUpdataLimit.vue?vue&type=template&id=c1fb7d68&scoped=true"
import script from "./MyColletionUpdataLimit.vue?vue&type=script&lang=js"
export * from "./MyColletionUpdataLimit.vue?vue&type=script&lang=js"
import style0 from "./MyColletionUpdataLimit.vue?vue&type=style&index=0&id=c1fb7d68&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c1fb7d68",
  null
  
)

export default component.exports