<template>
  <div id="my-colletion-filelimit">
    <div class="ct-filelimit-box">
      <div class="ct-filelimit-header">
        <span class="filelimit-text">{{getString(strings.User_Folder_Rename_Root)}}</span>
        <span class="filelimit-close-icon" @click="closeFilelimitModel">
          <img class="close-button-img" 
            src="../../assets/img/popupsImg/colse_new.svg"  
             />
        </span>
      </div>

      <div class="ct-filelimit-form">
        <a-radio-group v-model="value" @change="onChange">
          <div class="ct-public ct-box">
            <div class="ct-public-title">
              <span class="ct-titel">{{getString(strings.User_Folder_Root_Public)}}</span>
              <span class="ct-content"
                >{{getString(strings.User_Folder_Root_Public_Introduction)}}</span
              >
            </div>
            <div class="ct-public-radio">
              <a-radio :value="1"></a-radio>
            </div>
          </div>
          <div class="ct-public-look ct-box">
            <div class="ct-public">
              <div class="ct-public-title">
                <span class="ct-titel">{{getString(strings.User_Folder_Root_Viewonly)}}</span>
              </div>
              <div class="ct-public-radio">
                <a-radio :value="2"></a-radio>
              </div>
            </div>
          </div>
          <div class="ct-private ct-box">
            <div class="ct-public">
              <div class="ct-public-title">
                <span class="ct-titel">{{getString(strings.User_Folder_Root_Private)}}</span>
              </div>
              <div class="ct-public-radio">
                <a-radio :value="3"></a-radio>
              </div>
            </div>
          </div>
        </a-radio-group>
        <a-form-item class="newfile-submit">
          <a-button @click="closeFilelimitModel">{{getString(strings.Global_Cancel)}}</a-button>
          <a-button type="primary" @click="setFileLimitType">  {{getString(strings.Global_Ok)}} </a-button>
        </a-form-item>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { postUserModifyCollection } from "../../common/netWork/base_api";
import strings from "../../common/lang/strings";
import getString from "../../common/lang/language";

export default {
  data() {
    return {
      value: 1,
      isPrivate: "",
      canBeSaved: "",
      collectionId: "",
      strings: strings,
    };
  },
  computed: {
    ...mapState({
      colletionLimitFileData: (state) => state.colletionLimitFileModel.data,
    }),
  },
  mounted() {
    //绑定文件夹信息
    this.collectionId = this.colletionLimitFileData.id;
    this.isPrivate = this.colletionLimitFileData.isPrivate;
    this.canBeSaved = this.colletionLimitFileData.canBeSaved;
    if (!this.isPrivate && this.canBeSaved) {
      this.value = 1;
    } else if (!this.isPrivate && !this.canBeSaved) {
      this.value = 2;
    } else if (this.isPrivate && !this.canBeSaved) {
      this.value = 3;
    }
  },
  methods: {
    ...mapMutations(["updateCollectionFileLimit"]),
    //语言包
    getString(i) {
      return getString(i);
    },
    //设置文件限制类型
    setFileLimitType() {
      postUserModifyCollection(
        {
          collectionId: this.collectionId,
          isPrivate: this.isPrivate,
          canBeSaved: this.canBeSaved,
        },
        (res) => {
          this.$message.success(getString(strings.Message_Tips_Modification_Success));
        },
        (error) => {
          this.$message.error(error.desc);
        }
      );
      this.updateCollectionFileLimit({ accout: false });
    },
    //关闭设置文件限制类型
    closeFilelimitModel() {
      this.updateCollectionFileLimit({ accout: false });
    },
    //设置文件权限
    onChange(e) {
      this.value = e.target.value;
      if (e.target.value == 1) {
        this.isPrivate = false;
        this.canBeSaved = true;
      } else if (e.target.value == 2) {
        this.isPrivate = false;
        this.canBeSaved = false;
      } else if (e.target.value == 3) {
        this.isPrivate = true;
        this.canBeSaved = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
#my-colletion-filelimit {
  width: 524px;
  min-height: 180px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 2px 2px 10px 6px rgba(0, 0, 0, 0.16);
  z-index: 9999;
  border-radius: 10px;
  .ct-filelimit-box {
    // padding: 20px;
    .ct-filelimit-header {
      padding: 6px 20px;
      background: #efefef;
      border-radius: 10px 10px 0 0;
      .filelimit-text {
        display: flex;
        // justify-content: center;
        font-size: 18px;
        font-family: Noto Sans SC;
        /*font-weight: bold;*/
        line-height: 24px;
        color: #333333;
        opacity: 1;
      }
      .filelimit-close-icon {
        position: absolute;
        right: 20px;
        top: 7px;
        cursor: pointer;
        transition: 0.3s;
        color: #333333;
      }
      .filelimit-close-icon:hover {
        transition: 0.3s;
        color: #fd492b;
        transform: rotate(360deg);
      }
    }
    .ct-filelimit-form {
      margin-top: 10px;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding: 15px 20px;
      .ant-radio-group {
        width: 100%;
        .ct-box {
          margin: 10px 0;
        }
        .ct-public {
          width: 100%;
          height: 22px;
          display: flex;
          justify-content: space-between;
          .ct-public-title {
            .ct-titel {
              font-size: 14px;
              font-family: Noto Sans SC;
              font-weight: 400;
              line-height: 24px;
              color: #666666;
              opacity: 1;
            }
            .ct-content {
              font-size: 12px;
              font-family: Noto Sans SC;
              font-weight: 400;
              line-height: 24px;
              color: #999999;
              opacity: 1;
              margin-left: 20px;
            }
          }
        }
        .ct-public-look {
          width: 100%;
          height: 22px;
        }
        .ct-private {
          width: 100%;
          height: 22px;
        }
      }
      .newfile-submit {
        display: flex;
        justify-content: center;
        margin-bottom: 0;
        margin-top: 34px;
        flex-wrap: nowrap;
        button {
          width: 80px;
          height: 42px;
          opacity: 1;
          border-radius: 2px;
          margin: 0 10px;
          padding: 0 12px;
          span {
            text-align: center;
          }
        }
      }
    }
  }
}
.close-button-img{
    width:14px;
    cursor: pointer;
    opacity: 0.5;
}
.close-button-img:hover{
    opacity: 1;
}
</style>