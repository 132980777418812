<template>
  <div v-show="showBox" class="ieShowModeal">
      <div class="center-model">
          <div class="center-model-header">
              <div class="center-model-header-title">提示</div>
              <div  @click="hideModal" class="close-model">
                  <a-icon type="close" />
              </div>
          </div>
          <div class="center-model-content">
              <img class="center-model-content-img" src="../../assets/img/user/noIe.png" alt="">
              <div class="center-model-content-text">
                  您好，检测到您目前使用的浏览器兼容性较差，可能导致某些功能无法使用。为了您有更好的绘图体验，我们建议你使用谷歌浏览器。
              </div>
          </div>
        <div class="center-model-chrome-down" @click="openNewPage()">
            下载谷歌浏览器
        </div>
      </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            showBox: false
        }
    },
    created() {
    },
    methods: {
        hideModal () {
            this.showBox = false;
        },
        openNewPage () {
            window.open('https://www.google.cn/chrome/');
            this.hideModal();
        }
    },
    watch: {
        "$store.state.showIeHite"(newval, olval) {
            if(newval != -1 && newval != 'edge' ) {
                this.showBox = true;
            } else {
                this.showBox = false;
            }
        },
    },
}
</script>

<style lang="less" scoped>
    .ieShowModeal {
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 99999;
        background-color: rgba(0,0,0,.1);
        .center-model {
            position: absolute;
            width: 690px;
            height: 526px;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            border-radius: 9px;
            background-color: #fff;
            .center-model-header {
                display: flex;
                justify-content: space-between;
                margin-left: 30px;
                margin-right: 30px;
                height: 65px;
                align-items: center;
                border-bottom: 1px solid #E8E8E8;
                .center-model-header-title {
                    font-size: 18px;
                    color: #333;
                }
                .close-model {
                    cursor: pointer;
                }
            }
            .center-model-content {
                margin-left: 30px;
                margin-right: 30px;
                .center-model-content-img {
                    display: block;
                    margin: 0 auto;
                    margin-top: 84px;
                    width: 362px; 
                    height: 88px;
                }
                .center-model-content-text {
                    margin: 0 auto;
                    margin-top: 58px;
                    width: 540px;
                    height: 61px;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 36px;
                    color: #666666;
                    text-align: center;
                }
            }
            .center-model-chrome-down {
                margin: 0 auto;
                margin-top: 50px;
                width: 150px;
                height: 42px;
                background: #FD492B;
                border-radius: 21px;
                color: #fff;
                text-align: center;
                line-height: 42px;
                font-size: 14px;
                cursor: pointer;
                &:active {
                    background-color: #FF7354;
                }

            }
        }
    }
</style>