<template>
  <div class="bg-color-box">
    <div class="bg-color" v-if="ShowBg"></div>
    <a-config-provider :locale="locale">
      <a-layout
        :class="
          backgroundColor == 0 ? 'background-color-1' : 'background-color-2'
        "
        style="min-height: 100vh"
      >
        <a-layout-header
          class="header-box"
          :style="{
            display: 'none',
            position: 'relative',
            zIndex: 1,
            width: '100%',
            height: '60px',
            minWidth: '1200px',
            background: '#F5F5F5',
            borderBottom: '1px solid #c5c5c5',
          }"
        >
          <div class="header-top" ref="headerTop">
            <!-- 思维导图Logo -->
            <div class="product-name">
              <div>
                <template v-if="nowElectron">
                  <img
                    class="official-website-img"
                    @click.right="showLeftNavigation()"
                    @click="jumpOfficialWebsite()"
                    src="../../assets/img/code/logo02.svg"
                    alt="logo"
                  />
                </template>
                <template v-else>
                  <img
                    src="../../assets/img/code/logo02.svg"
                    @click.right.prevent="showLeftNavigation()"
                    alt="logo"
                  />
                </template>
                <!-- <span class="content-header-text" @dblclick="reload()">
                  {{ getString(Strings.About_App_Name) }}
                </span> -->
              </div>
              <!-- 菜单栏 -->
              <!-- <div class="menu-title"> -->
              <!-- addstyle -->
              <!-- <div
                  class="menu-name"
                  v-for="(item, index) in menuNameList"
                  :key="index"
                  @click="sliderMenuRouter(index)"
                >
                  <div
                    class="menu-name-text"
                    :class="clickButton == index ? 'addstyleColor' : ''"
                  >
                    <img
                      v-if="clickButton == index"
                      :src="item.checkIcon"
                      alt=""
                    />
                    <img v-else :src="item.icon" alt="" />
                    {{ getString(item.name) }}
                  </div>
                  <div :class="clickButton == index ? 'addstyle' : ''"></div>
                </div>
              </div> -->
            </div>

            <!-- 顶部右侧 -->
            <div class="product-down">
              <div
                class="recommend-select"
                :class="{ 'recommend-focus-select': showFocusRecommendSelect }"
                v-if="routerNameIsStorm"
              >
                <a-input-search
                  :placeholder="howRecommendMoreTitle.hotSearch"
                  v-model="howRecommendMoreValue"
                  style="width: 282px"
                  @search="onSearch"
                  @focus="isShowRecommendSelect()"
                  @blur="showFocusRecommendSelect = false"
                  ref="howValue"
                />
                <div class="recommend-change" v-if="showRecommendSelect">
                  <div class="seach-content" @click="toStormSearch(1)">
                    {{ getString(Strings.Search_Shared_Groups) }}
                  </div>
                  <div class="seach-content" @click="toStormSearch(0)">
                    {{ getString(Strings.Search_Storm) }}
                  </div>
                </div>
                <div class="recommend-content" v-if="false">
                  <div class="recommend-padding">
                    <div class="recommend-box">
                      <div class="recommend-title">
                        {{ getString(Strings.Storm_Search_How_Title) }}
                      </div>
                      <div class="recommend-title-more-box">
                        <div
                          class="recommend-more-title"
                          v-for="(item, index) in howRecommendMoreTitle.tags"
                          :key="index"
                          @click="
                            recommendStormToTarget(item.id, 'STORM_TO_BOOKTAG')
                          "
                        >
                          #{{ item.name }}
                        </div>
                      </div>
                    </div>
                    <div class="recommend-box">
                      <div class="recommend-title">
                        <span>{{
                          getString(Strings.Storm_History_Search)
                        }}</span>
                        <img
                          class="recommend-delete"
                          @click="isShowDesitoryData"
                          src="../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/commend_delete_selecte.png"
                          alt=""
                        />
                      </div>
                      <div class="recommend-title-more-box">
                        <div
                          class="recommend-more-title"
                          v-for="(item, index) in searchDataHistory"
                          :key="index"
                        >
                          <span
                            @click="
                              recommendStormToTarget(
                                item.name,
                                'STORM_TO_NEW_STORM'
                              )
                            "
                            >{{ item.name }}</span
                          >
                          <img
                            class="recommend-delete"
                            v-if="desitoryButtom"
                            src="../../../static/img/userData/share_pc_close.png"
                            @click="deleteDesitorySelect(index)"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 存储空间 -->
              <div
                class="storage-content"
                v-if="showStorageInfo && showStorageRouter"
              >
                <div class="storage-message">
                  {{ getString(Strings.Used_Space) }}：{{ storage.used }} /
                  {{ storage.total }}
                </div>
                <div class="storage-progress">
                  <a-progress
                    :percent="storage.percent"
                    :stroke-color="{
                      '0%': '#ff7c65',
                      '100%': '#fd492b',
                    }"
                    :showInfo="false"
                  />
                </div>
              </div> 
              <!-- 购买 -->
              <div>
                <!-- class="divshoping-car" -->
                <a-button class="divshoping" @click="ShowShoppingModel">
                  <img
                    class="shoping-car"
                    src="../../assets/img/user/group732.png"
                    alt
                    style="margin-top: -3px; margin-right: 5px; width: 15px"
                  />
                  {{ getString(Strings.Mind_Edit_Upgrade) }}
                </a-button>
              </div>
            </div>
          </div>
        </a-layout-header>

        <a-layout>
          <a-layout-sider
            ref="leftNavigationBox"
            v-show="leftNavigation"
            class="sider-box1"
            :class="{ 'black-backgroundcolor': true }"
            :style="{
              overflow: 'auto',
              height: '100vh',
              position: 'fixed',
              left: 0,
              overflowX: 'hidden'
            }"
            ><!--maxWidth:'210px',width:'210px',-->
            <div style="border-right: 1px solid #ffffff">
              <div class="user-account-box">
                <!-- 是否登录 -->
                <a-tooltip
                  placement="bottom"
                  :mouseEnterDelay="0.2"
                  v-if="meMessage"
                >
                  <template slot="title" class="user-data">
                    <!-- 个人信息 -->
                    <div class="user-data">
                      <p
                        @click="setUserMessageDetails"
                        style="margin-top: 10px"
                      >
                        <img src="../../assets/img/user/me_user.svg" alt />
                        {{ getString(Strings.Checking_Data) }}
                      </p>
                      <!-- 个人账户 -->
                      <p @click="individualAccount" style="margin-top: 10px">
                        <img src="../../assets/img/user/my_account.svg" alt />
                        {{ getString(Strings.Individual_Account) }}
                      </p>
                      <p @click="setUserMessage" style="margin-top: 10px">
                        <img
                          src="../../assets/img/user/setting_information.svg"
                          alt
                        />
                        {{ getString(Strings.Setting_Information) }}
                      </p>
                      <p style="width: 155px; height: 0px; opacity: 1"></p>
                      <!-- border: 1px solid #E0E0E0; -->
                      <!-- 退出账户 -->
                      <p
                        @click="pushOutLogin"
                        @click.right.stop.prevent="switchAccount()"
                      >
                        <img src="../../assets/img/user/sign_out.svg" alt />
                        {{ getString(Strings.Sign_Out) }}
                      </p>
                    </div>
                  </template>
                  <div class="account-login">
                    <div class="head_avatar">
                      <img
                        v-show="meMessage.isMember"
                        class="crown"
                        src="../../assets/img/user/crown.png"
                        alt
                      />
                      <div
                        class="head_avatar_icon"
                        :style="
                          'width: 48px; height: 48px; border-radius: 50%; background:url(' +
                          meMessage.avatar +
                          ') no-repeat; background-size: cover; background-position:center; overflow:hidden;'
                        "
                        @click.right.stop.prevent="switchAccount()"
                      ></div>
                    </div>
                    <div style="text-align: center">
                      {{ meMessage.username }}
                    </div>
                    <div
                      v-show="meMessage.isMember"
                      style="
                        text-align: center;
                        color: #ababab;
                        font-size: 12px;
                      "
                    >
                      {{ getString(Strings.User_Member_Validity_Period) }}:{{
                        meMessage.memberExpire
                      }}
                    </div>
                  </div>
                </a-tooltip>
                <div class="account-no-login" style="text-align: center" v-else>
                  <div class="account-login">
                    <div class="head_avatar">
                      <img
                        @click="ShowLoginModel"
                        class="crown"
                        src="../../assets/img/user/currency/not_logged_in.svg"
                        style="margin-top: 10px"
                        alt
                      />
                      <!-- <div
                      class="head_avatar_icon"
                      :style="
                        'width: 68px; height: 68px; border-radius: 50%; background:url(' +
                        meMessage.avatar +
                        ') no-repeat; background-size: cover; background-position:center; overflow:hidden;'
                      "
                    ></div> -->
                    </div>
                    <span @click="ShowLoginModel" class="login_out" style=""
                      >{{ getString(Strings.User_Lolgin_Registered) }}/{{
                        getString(Strings.Global_Login)
                      }}</span
                    >
                  </div>
                  <!-- <a-button @click="ShowLoginModel">{{
                  getString(Strings.Global_Login)
                }}</a-button> -->
                </div>
                <!-- <div class="account_underscore"></div> -->

                <!-- 存储空间 -->
                <div
                  class="storage-content"
                  style="justify-content:center; margin-top: 1px"
                  v-if="showStorageInfo && showStorageRouter"
                  >
                  <div class="storage-message">
                    {{ getString(Strings.Used_Space) }}：{{ storage.used }} /
                    {{ storage.total }}
                    <img @click="ShowShoppingModel"
                      src="../../assets/img/user/currency/upgrade_icon.svg"
                      style="width:13; height: 13px; margin-left: 4px; cursor:pointer;"
                    />
                  </div>
                  <div class="storage-progress" @click="ShowShoppingModel" style="cursor:pointer;">
                    <a-progress
                      :percent="storage.percent"
                      :stroke-color="{
                        '0%': '#ff7c65',
                        '100%': '#fd492b',
                      }"
                      :showInfo="false"
                    />
                  </div>
                </div>
              </div>
              <div class="slider-mind-created-about" ref="mindCreated">
                <div style="height: 108px;">
                  <div
                    @click="slider('CreatedFile', 1)"
                    :class="showCreated ? 'created-each-box-selected' : ''"
                    class="created-each-box created-default"
                    style="font-weight: 600"
                  >
                    <img
                      src="../../assets/img/user/currency/created_mind.svg"
                      class="created-each-box-img"
                    />
                    <span
                      class="color-deepen"
                      style="top: 2px; position: relative"
                      >{{ getString(Strings.Establish) }}</span
                    >
                    <div class="created-each-box-gray"></div>
                  </div>

                  <div
                    class="created-each-box"
                    @click="sliderMenuRouter(2)"
                    :class="
                      showTemplateCreated ? 'created-each-box-selected' : ''
                    "
                  >
                    <img
                      src="../../assets/img/user/currency/temp_created_mind.svg"
                      class="created-each-box-img"
                    />
                    <span
                      class="color-deepen"
                      style="top: 2px; position: relative"
                      >{{ getString(Strings.Create_From_Template) }}</span
                    >
                    <div class="created-each-box-gray"></div>
                  </div>
                  <!-- 导入 -->
                  <div
                    class="created-each-box"
                    @click="showFileImportFun"
                    @click.right.stop.prevent="turnOnScanCreation()"
                    :class="
                      showFileImportModal ? 'created-each-box-selected' : ''
                    "
                  >
                    <img
                      src="../../assets/img/user/currency/importFileIcon.svg"
                      class="created-each-box-img"
                    />
                    <span
                      class="color-deepen created-import"
                      style="top: 2px; position: relative"
                    >
                      {{ getString(Strings.My_Mind_Open) }}
                    </span>
                    <div class="created-each-box-gray"></div>
                  </div>
                </div>
              </div>
              <div
                class="right-check-public"
                style="
                  height: 2px;
                  background: #fff;
                  margin-left: 10px;
                  margin-right: 10px;
                  border-bottom: 1px solid #d0d0d0;
                "
              ></div>
              <div class="slider-mind-public-about">
                <div class="mind-public-content" ref="publicContent">
                  <div
                    @click="sliderMenuRouter(0)"
                    :class="
                      clickButton == 0 ? 'right-check-public-selected' : ''
                    "
                    class="right-check-public"
                  >
                    <img
                      src="../../assets/img/user/currency/recentl_ycreated.svg"
                      class="icon iconfont1-slider"
                    />
                    <img
                      src="../../assets/img/user/currency/recentl_ycreated.svg"
                      class="icon-active iconfont1-slider"
                    />
                    <span class="slider-icon-name color-deepen">{{
                      getString(Strings.My_map)
                    }}</span>
                  </div>
                  <div
                    @click="slider(routerParameters.Archive, 1)"
                    :class="
                      clickButton == 'Archive'
                        ? 'right-check-public-selected'
                        : ''
                    "
                    class="right-check-public right-check-item2"
                  >
                    <img
                      src="../../assets/img/user/currency/my_archive.svg"
                      class="icon iconfont1-slider"
                    />
                    <img
                      src="../../assets/img/user/currency/my_archive.svg"
                      class="icon-active iconfont1-slider"
                    />
                    <span class="slider-icon-name color-deepen">{{
                      getString(Strings.Mind_Archive)
                    }}</span>
                  </div>
                  <div
                    @click="sliderMenuRouter(1)"
                    :class="
                      clickButton == 1 ? 'right-check-public-selected' : ''
                    "
                    class="right-check-public right-check-item3"
                  >
                    <img
                      src="../../assets/img/user/currency/shared_group.svg"
                      class="icon iconfont1-slider"
                    />
                    <img
                      src="../../assets/img/user/currency/shared_group.svg"
                      class="icon-active iconfont1-slider"
                    />
                    <span class="slider-icon-name color-deepen">{{
                      getString(Strings.Mind_Edit_Page_Sharing_Group)
                    }}</span>
                  </div>
                  <div
                    class="right-check-public"
                    style="
                      height: 2px;
                      background: #fff;
                      border-bottom: 1px solid #d0d0d0;
                    "
                  ></div>
                  <div
                    @click="sliderMenuRouter(3)"
                    :class="
                      clickButton == 3 ? 'right-check-public-selected' : ''
                    "
                    class="right-check-public right-check-item4"
                  >
                    <img
                      src="../../assets/img/user/currency/storm.svg"
                      class="icon iconfont1-slider"
                    />
                    <img
                      src="../../assets/img/user/currency/storm.svg"
                      class="icon-active iconfont1-slider"
                    />
                    <span class="slider-icon-name color-deepen">{{
                      getString(Strings.Storm)
                    }}</span>
                    <img
                      class="fiery_start"
                      style="display:none"
                      src="../../assets/img/user/currency/fiery.svg"
                    />
                  </div>
                  <div
                    class="right-check-public"
                    style="
                      height: 2px;
                      background: #fff;
                      border-bottom: 1px solid #d0d0d0;
                    "
                  ></div>
                  <div
                    @click="slider('NewDelet', 1)"
                    :class="
                      clickButton == 'NewDelet'
                        ? 'right-check-public-selected'
                        : ''
                    "
                    class="right-check-public"
                  >
                    <img
                      src="../../assets/img/user/currency/recently_deleted.svg"
                      class="icon iconfont1-slider"
                    />
                    <img
                      src="../../assets/img/user/currency/recently_deleted.svg"
                      class="icon-active iconfont1-slider"
                    />
                    <span class="slider-icon-name">{{
                      getString(Strings.The_Latest_To_Delete)
                    }}</span>
                  </div>
                  <div
                    @click="slider('Setting', 1)"
                    :class="setting ? 'right-check-public-selected' : ''"
                    class="right-check-public"
                  >
                    <img
                      src="../../assets/img/user/currency/contact_us.svg"
                      class="icon iconfont1-slider"
                    />
                    <img
                      src="../../assets/img/user/currency/contact_us.svg"
                      class="icon-active iconfont1-slider"
                    />
                    <span class="slider-icon-name">{{
                      getString(Strings.Mind_Group_Setting_Up)
                    }}</span>
                  </div>

                  <div
                    @click="slider('upgrade_vip', 1)"
                    :class="
                      clickButton == 'StudentCertification'
                        ? 'right-check-public-selected'
                        : ''
                    "
                    class="right-check-public right-check-item3"
                  >
                    <!-- <div class="before-circle"></div> -->
                    <!-- <span class="iconfont1">&#xe60e;</span> -->

                    <img
                      src="../../assets/img/user/currency/upgrade_normal_icon.svg"
                      class="icon iconfont1-slider"
                    />
                    <img
                      src="../../assets/img/user/currency/upgrade_icon.svg"
                      class="icon-active iconfont1-slider"
                    />
                    <span class="slider-icon-name">{{
                      getString(Strings.Mind_Edit_Upgrade_Item)
                    }}</span>
                  </div>
                  <div
                    @click="slider('InvitationCode', 1)"
                    :class="
                      clickButton == 'InvitationCode'
                        ? 'right-check-public-selected'
                        : ''
                    "
                    class="right-check-public right-check-item3"
                  >
                    <!-- <div class="before-circle"></div> -->
                    <!-- <span class="iconfont1">&#xe60e;</span> -->

                    <img
                      src="../../assets/img/user/currency/invitation_code_icon.svg"
                      class="icon iconfont1-slider"
                    />
                    <img
                      src="../../assets/img/user/currency/invitation_code_icon_selected.svg"
                      class="icon-active iconfont1-slider"
                    />
                    <span class="slider-icon-name">{{
                      getString(Strings.Invitation_Code)
                    }}</span>
                  </div>

                  <div
                    @click="slider('SettingUp', 1)"
                    :class="
                      clickButton == 'SettingUp'
                        ? 'right-check-public-selected'
                        : ''
                    "
                    class="right-check-public right-check-item3"
                  >
                    <!-- <div class="before-circle"></div> -->
                    <!-- <span class="iconfont1">&#xe60e;</span> -->

                    <img
                      src="../../assets/img/user/currency/using_help.svg"
                      class="icon iconfont1-slider"
                    />
                    <img
                      src="../../assets/img/user/currency/using_help_check.svg"
                      class="icon-active iconfont1-slider"
                    />
                    <span class="slider-icon-name">{{
                      getString(Strings.Setting_Up)
                    }}</span>
                  </div>
                  <div
                    @click="slider('Help', 1)"
                    :class="
                      clickButton == 'Help' ? 'right-check-public-selected' : ''
                    "
                    class="right-check-public right-check-item4"
                  >
                    <img
                      src="../../assets/img/user/currency/set_up.svg"
                      class="icon iconfont1-slider"
                    />
                    <img
                      src="../../assets/img/user/currency/set_up_check.svg"
                      class="icon-active iconfont1-slider"
                    />
                    <span class="slider-icon-name">{{
                      getString(Strings.Usinghelp)
                    }}</span>
                  </div>
                  <div class="right-check-public right-check-item4"></div>
                </div>
              </div>
              <div
                class="slider-mind-footer-about"
                :class="
                  nowElectron
                    ? 'slider-mind-footer-about-PC'
                    : 'slider-mind-footer-about-web'
                "
                ref="mindFooter"
              >
                <!-- 下载客户端 -->
                <div
                  class="down-coumputer"
                  :class="
                    nowElectron ? 'down-coumputer-PC' : 'down-coumputer-web'
                  "
                >
                  <div
                    class="down-text"
                    :class="nowElectron ? 'down-text-PC' : 'down-text-web'"
                  >
                    <span style="font-size: 12px">{{
                      getString(Strings.The_Download_Client)
                    }}</span>
                  </div>
                  <ul class="dc-platform">
                    <li>
                      <template v-if="nowElectron">
                        <img
                          @click="
                            jumpOfficialWebsite('https://web.mindyushu.com/')
                          "
                          class="icon-font-size"
                          src="../../assets/img/user/Iitem0selected/chromeIcon.svg"
                          alt
                        />
                      </template>
                      <template v-else>
                        <a
                          href="https://www.mindyushu.com/downloads.html"
                          target="_self"
                        >
                          <img
                            class="icon-font-size"
                            src="../../assets/img/user/Iitem0selected/buy0145.svg"
                            alt
                          />
                        </a>
                      </template>
                    </li>
                    <li>
                      <template v-if="nowElectron">
                        <template v-if="isWindows">
                          <img
                            @click="
                              jumpOfficialWebsite(
                                'https://www.mindyushu.com/downloads.html'
                              )
                            "
                            class="icon-font-size"
                            src="../../assets/img/user/Iitem0selected/buy0338.svg"
                            alt
                          />
                        </template>
                        <template v-if="isMac">
                          <img
                            @click="
                              jumpOfficialWebsite(
                                'https://www.mindyushu.com/downloads.html'
                              )
                            "
                            class="icon-font-size"
                            src="../../assets/img/user/Iitem0selected/buy0145.svg"
                            alt
                          />
                        </template>
                      </template>
                      <template v-else>
                        <a href="https://www.mindyushu.com/" target="_self">
                          <img
                            class="icon-font-size"
                            src="../../assets/img/user/Iitem0selected/buy0338.svg"
                            alt
                          />
                        </a>
                      </template>
                    </li>
                    <li class="dp-from">
                      <div class="fushang">
                        <span class="fs-shangM">
                          <img
                            class="shang-mImg"
                            src="../../assets/img/user/official-site-qrcode.png"
                            alt=""
                          />
                        </span>
                        <img
                          class="kuang-beijingImg"
                          src="../../assets/img/user/Iitem0selected/suspend1225.png"
                          alt=""
                        />
                      </div>
                      <!-- <a href="https://www.mindyushu.com/" target="_self">
                      <img
                        class="icon-font-size"
                        src="../../assets/img/user/Iitem0selected/buy0338.png"
                        alt
                      />
                    </a> -->
                      <template v-if="nowElectron">
                        <img
                          @click="
                            jumpOfficialWebsite(
                              'https://www.mindyushu.com/downloads.html'
                            )
                          "
                          class="icon-font-size"
                          src="../../assets/img/user/Iitem0selected/buy0237.svg"
                          alt
                        />
                      </template>
                      <template v-else>
                        <a href="https://www.mindyushu.com/" target="_self">
                          <img
                            class="icon-font-size"
                            src="../../assets/img/user/Iitem0selected/buy0237.svg"
                            alt
                          />
                        </a>
                      </template>
                    </li>
                    <li class="dp-from">
                      <div class="fushang">
                        <span class="fs-azshangM">
                          <img
                            class="shang-mImg"
                            src="../../assets/img/user/Iitem0selected/iosQrcode.png"
                            alt=""
                          />
                        </span>
                        <img
                          class="kuang-azbeijingImg"
                          src="../../assets/img/user/Iitem0selected/suspend1225.png"
                          alt=""
                        />
                      </div>
                      <!-- <a>
                      <img
                        class="icon-font-size"
                        src="../../assets/img/user/Iitem0selected/buy0440.png"
                        alt
                      />
                    </a> -->
                      <template v-if="nowElectron">
                        <img
                          @click="
                            jumpOfficialWebsite(
                              'https://www.mindyushu.com/downloads.html'
                            )
                          "
                          class="icon-font-size"
                          src="../../assets/img/user/Iitem0selected/buy0440.svg"
                          alt
                        />
                      </template>
                      <template v-else>
                        <a href="https://www.mindyushu.com/" target="_self">
                          <img
                            class="icon-font-size"
                            src="../../assets/img/user/Iitem0selected/buy0440.svg"
                            alt
                          />
                        </a>
                      </template>
                    </li>
                  </ul>
                </div>
                <!-- <div class="slider-mind-footer-about-gray">
              </div> -->
              </div>
            </div>
          </a-layout-sider>

          <a-layout-content
            class="main-content"
            :style="{ 'padding-left': `${leftNavigationWidth}` }"
            @contextmenu.prevent.stop=""
          >
            <div class="main-content-children-box">
              <div
                ref="mainContentChildren"
                class="main-content-children"
                :style="
                  'height:' + fullHeight + 'px;' + 'position:' + 'relative;'
                "
              >
                <keep-alive>
                  <router-view v-if="$route.meta.keepAlive"></router-view>
                </keep-alive>
                <router-view v-if="!$route.meta.keepAlive"></router-view>
              </div>
            </div>

            <div class="footer-box"></div>
          </a-layout-content>
          <!-- 登录模态框 -->

          <!-- 分享链接 -->
          <div class="share-pc">
            <ComplieLeftMenuShare v-if="shareModelAccout" />
          </div>
          <!-- 新建文件夹 -->
          <div class="share-pc">
            <MyColletionNewFile v-if="myCollectionNewFileModel" />
          </div>
          <div class="share-pc">
            <MyColletionUpdataLimit v-if="myColletionLimitFileModel" />
          </div>
          <div class="share-pc-move">
            <MycollectionMoveFile v-if="myColltionMoveFileModel" />
          </div>
        </a-layout>
        <!-- <a-layout> -->
        <!-- <keep-alive> -->
        <!-- <router-view
              v-if="
                $router.history.current.name == 'GroupManagement'
              "
            ></router-view> -->
        <!-- </keep-alive> -->
        <!-- <router-view
            v-if="
              !$route.meta.keepAlive &&
              $router.history.current.name == 'GroupManagement'
            "
          ></router-view> -->
        <!-- </a-layout> -->
        <div class="childlogin">
          <transition name="slide-fade">
            <LoginComponents
              v-if="userModel"
              class="father-login"
              @HideUserModel="HideUserModel"
              @ShowHome="ShowHome"
            />
          </transition>
        </div>

        <!-- 商品购物车 -->
        <div class="Shopping-Father-Box" @click.stop="HideShoppingModel(false)">
          <transition name="slide-fade">
            <ShoppingModel
              v-if="ShoppingModel"
              :showMsg="showMsg"
              @HideShoppingModel="HideShoppingModel"
              @shoppJumpLogin="shoppJumpLogin"
            />
          </transition>
        </div>
        <!-- 用户信息 -->
        <div class="usermessage-father-box">
          <transition name="slide-fade">
            <Usermessage
              v-if="userMessage"
              @HideUserMessage="HideUserMessage"
            />
          </transition>
        </div>
        <!-- 分享风暴 -->
        <div class="share-pc">
          <transition name="slide-fade">
            <SharePotatoChips v-if="sharePotatoChips" />
          </transition>
        </div>
        <!-- 风暴 -->
        <!-- <Storm v-if="chipsPageAccout" :newSelectStormData="selectStormData" /> -->
        <!-- <StormBookTag v-if="stormBookTagAccout" /> -->
        <!-- <MoreGroups v-if="stormMoreGroups" />
        <MoreCrisps v-if="stormMoreCrisps" /> -->
        <!-- <StormTag v-if="stormTag" />
        <StormSearch v-if="stormSearch" /> -->
        <!-- 用户中心 -->
        <LookUserMessage v-if="userDataModel" />
      </a-layout>
    </a-config-provider>
    <IeShowModal />
    <AboutProject
      :showAboutProject="showAboutProject"
      @showAboutModal="showAboutModal"
    />
    <template v-if="!isLatestVersion && nowElectron">
      <VersionUpdatingModal />
    </template>
    <!-- 存储空间已满 storage.ShowAlert -->
    <a-modal
      class="report-member-model"
      :centered="true"
      v-model="storageIsMeberModelAccout"
      :title="getString(Strings.Mind_Edit_Join_Membership)"
      :cancelText="getString(Strings.Global_Cancel)"
      :okText="getString(Strings.Mind_Edit_Upgrade)"
      @cancel="storageIsMeberCancelHandleOk"
      @ok="storageIsMeberDeleteHandleOk"
    >
      <div class="report-crisps-style-box">
        <div
          class="report-crisps-style"
          style="
            font-size: 18px;
            font-family: Noto Sans SC;
            text-align: center;
            display: block;
          "
        >
          {{ getString(Strings.Insufficient_Storage_Space) }}
        </div>
      </div>
    </a-modal>
    <ComplieHelp />
    <!-- 设置 -->
    <section v-if="setting" class="setting-bg">
      <Setting @HideSettingModel="HideSettingModel" />
    </section>
    <SettingUpPopup
      :contactShow="showContactUs"
      @showContactModal="showContactModal"
    />
    <CreatedMind :moveFile="showCreatedMind" @hideMoveFile="hideCreatedBox" />
    <!-- 导入文件 -->
    <MyFileImport
      :show="showFileImportModal"
      @hiddenFileImportModal="hiddenFileImportModal"
    />
    <invitedCodeModal
      v-if="showInvitedCode"
      @HideInvitedCodeModel="HideInvitedCodeModel"
    />
  </div>
</template>

<script>
if (
  typeof navigator === "object" &&
  typeof navigator.userAgent === "string" &&
  navigator.userAgent.indexOf("Electron") >= 0
) {
  var { ipcRenderer, remote } = window.require("electron");
  // var ipcRenderer = window.ipcRenderer
}
import ComplieHelp from "../complieComponents/ComplieHelp/ComplieHelp";
import Setting from "../universalFile/setting/Setting";
import SettingUpPopup from "../electronMenu/SettingUpPopup";
import CreatedMind from "../electronMenu/CreatedMind";
import LoginComponents from "../loginComponents/LoginComponents";
import ShoppingModel from "../shopping/ShoppingModel";
import Usermessage from "../userMessage/UserMessage";
import IeShowModal from "../IeShowModal/IeShowModal";
import VersionUpdatingModal from "../versionUpdatingModal/versionUpdatingModal";
import SharePotatoChips from "../sharePotatochips/SharePotatochips";
import ComplieLeftMenuShare from "../../components/complieComponents/ComplieLeftMenuChild/ComplieLeftMenuShare";
import MyColletionNewFile from "../UserCollectionFile/MyColletionNewFile";
import MyColletionUpdataLimit from "../UserCollectionFile/MyColletionUpdataLimit";
import MycollectionMoveFile from "../UserCollectionFile/MycollectionMoveFile";
import Storm from "./Storm";
import StormBookTag from "./StormBookTag";
import MoreGroups from "./stormChild/MoreGroups.vue";
import MoreCrisps from "./stormChild/MoreCrisps";
import StormTag from "./stormChild/StormTag";
import StormSearch from "./stormChild/StormSearch";
import AboutProject from "../aboutProject/aboutProject";
import LookUserMessage from "../userMessage/LookUserMessage";
import MyFileImport from "../fileImpotr/MyFileImport";
import publicModalStyle from "../../assets/css/publicModalStyle/publicModalStyle.less";
import JsExtend from "../../utils/JsExtend";
import {
  postUserLogout,
  postUserMe,
  postUserCheckUpdate,
} from "../../common/netWork/base_api";
import { postUserStorageInfo } from "../../common/netWork/networkcache";
import {
  doLogin,
  doLogout,
  getJwt,
  getCookie,
} from "../../common/netWork/base";

import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";

import { mapMutations } from "vuex";
import {
  postCrispsListHotSearch,
  postCrispsSearch,
} from "../../common/netWork/crisps_api";
import Strings from "../../common/lang/strings";
import getString from "../../common/lang/language";
import dataTimeToFormat from "../../utils/timestampToTime";
import ConfigurationItem from "../../assets/js/ConfigurationItem.js";
const { numberOfMapsTourist } = ConfigurationItem;
import Config from "../../core/core/calcule/Config";
import Util from "../../utils/Util";
import invitedCodeModal from "../invitedCode/invitedCodeModal";
import HttpServerConfig from "../../common/HttpServerConfig";
import { eventBusMethod } from "../../assets/js/GlobalVariable.js";
import MindMe from '../../viewmodel/facade/MindMe';
import XMindFileImport from '../../core/core/dataformat/fileImport/XMindFileImport';
import XJMindFIleImport from '../../core/core/dataformat/fileImport/XJMindFIleImport';
moment.locale("zh-cn");
// const EN = "en-us";
// const ZH = "zh-cn";
export default {
  inject: ["reload", "refreshComponent"],
  components: {
    LoginComponents,
    ShoppingModel,
    Usermessage,
    IeShowModal,
    SharePotatoChips,
    ComplieLeftMenuShare,
    MyColletionNewFile,
    MyColletionUpdataLimit,
    MycollectionMoveFile,
    AboutProject,
    Storm,
    StormBookTag,
    MoreGroups,
    MoreCrisps,
    StormTag,
    StormSearch,
    LookUserMessage,
    VersionUpdatingModal,
    ComplieHelp,
    Setting,
    SettingUpPopup,
    CreatedMind,
    MyFileImport,
    invitedCodeModal,
  },
  data() {
    return {
      backgroundColor: 0, //网页的色调
      fullHeight: document.documentElement.clientHeight - 10, //中间内容部分的高度
      fullWidth: document.documentElement.clientWidth,
      clickButton: 0, //头部的消息公告、模板的等是否选中
      locale: zhCN,
      localeval: "zh-cn",
      message: "2",
      userModel: false,
      // userAccount: false,
      ShoppingModel: false,
      ShowBg: false,
      showMsg: false, //M豆购买
      userMessage: false,
      meMessage: "", //用来存储用户的信息
      routerNameIsStorm: "", //路由名称, 用于判断风暴的顶部输入框是否展示
      sharePotatoChips: false,
      shareModelAccout: false, //分享链接模态框
      myCollectionNewFileModel: false, //新建文件夹
      myColletionLimitFileModel: false, //修改文件夹权限
      myColltionMoveFileModel: false, //移动文件夹
      nowElectron: false, //是否是electron环境
      showAboutProject: false, //是否展示关于弹框
      isMac: false, //是否是mac环境
      isWindows: false, //是否是window环境
      // menuNameList: [Strings.My_map, Strings.TEMPLATE, Strings.Storm],
      // menuNameList: [Strings.My_map, Strings.TEMPLATE, Strings.Storm, Strings.Group_Management],
      // menuNameList: [{name:Strings.My_map, icon: require('../../assets/img/homeHeaderIcon/titleIcon1.png'), checkIcon: require('../../assets/img/homeHeaderIcon/titleIcon1-2.png')},
      //   {name:Strings.TEMPLATE, icon: require('../../assets/img/homeHeaderIcon/titleIcon3.png'), checkIcon: require('../../assets/img/homeHeaderIcon/titleIcon3-2.png')},
      //   {name:Strings.Storm, icon: require('../../assets/img/homeHeaderIcon/titleIcon4.png'), checkIcon: require('../../assets/img/homeHeaderIcon/titleIcon4-2.png')},
      //   {name:Strings.Group_Management, icon: require('../../assets/img/homeHeaderIcon/titleIcon2.png'), checkIcon: require('../../assets/img/homeHeaderIcon/titleIcon2-2.png')},
      // ],
      menuNameList: [
        {
          name: Strings.My_map,
          icon: require("../../assets/img/homeHeaderIcon/icon/myMind-gray.svg"),
          checkIcon: require("../../assets/img/homeHeaderIcon/icon/myMind-red.svg"),
        },
        {
          name: Strings.Mind_Edit_Page_Sharing_Group,
          icon: require("../../assets/img/homeHeaderIcon/icon/group-gray.svg"),
          checkIcon: require("../../assets/img/homeHeaderIcon/icon/group-red.svg"),
        },
        {
          name: Strings.TEMPLATE,
          icon: require("../../assets/img/homeHeaderIcon/icon/modal-gray.svg"),
          checkIcon: require("../../assets/img/homeHeaderIcon/icon/modal-red.svg"),
        },
        {
          name: Strings.Storm,
          icon: require("../../assets/img/homeHeaderIcon/icon/storm-gray.svg"),
          checkIcon: require("../../assets/img/homeHeaderIcon/icon/storm-red.svg"),
        },
        {
          name: Strings.Checking_Data,
          icon: require("../../assets/img/homeHeaderIcon/icon/personal-info-gray.svg"),
          checkIcon: require("../../assets/img/homeHeaderIcon/icon/personal-info-red.svg"),
        },
      ],

      homeBorderComponentAccout: false, //是否展示左边栏和右边内容部分。
      chipsPageAccout: false, //风暴的内容是否展示。
      stormBookTagAccout: false, //风暴的顶部标签选择框是否展示。
      stormMoreGroups: false, //更多云组
      stormMoreCrisps: false, //更多风暴
      stormTag: false, //风暴标签详情
      stormSearch: false, //风暴搜索页面
      loginModel: false,
      howRecommendMoreTitle: [], //热门标签
      howRecommendMoreValue: "", //热门标签搜索内容
      showRecommendSelect: false, //搜索历史and搜索热词
      showFocusRecommendSelect: false, //搜索按钮高亮
      selectStormData: {}, //搜索到的风暴数据
      userDataModel: false, //用户中心
      searchDataHistory: [], //历史记录 用于本地存储
      desitoryButtom: false, //删除按钮
      isLatestVersion: true, // 是否是最新版本
      Strings: Strings, //语言文字
      storage: null, //存储空间使用情况
      showStorageInfo: false, //是否显示存储空间
      storageIsMeberModelAccout: false, //是否显示付费入口
      showStorageRouter: false, //存储空间的大小是否展示
      setting: false,
      showContactUs: false, //是否展示联系我们弹框
      showCreatedMind: false, //创建导图弹窗
      routerParameters: {
        Archive: "Archive",
      },

      showCreated: false, //判断是否选中创建
      showTemplateCreated: false, //判断是否选中模板创建
      // show
      showFileImportModal: false, //导入文件弹窗
      currentRouter: "",
      showInvitedCode: false,
      leftNavigation: true, //左侧导航
      leftNavigationWidth: "200px", //左侧导航宽度
    };
  },
  created() {
    if (
      window.navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i // midp|iphone os|ipad|ucweb|windows mobile|
      )
    ) {
      this.$router.push("/download");
      return;
    } else {
    }
    this.getLocalstorageUser();
    this.isPageName();

    this.nowElectron = this.$tools.isElectron();
    //是否是mac
    this.isMac = this.$tools.isMac();
    this.isWindows = this.$tools.isWindows();

    if (document.hidden !== undefined) {
      document.addEventListener("visibilitychange", () => {
        if (document.hidden) {
          return;
        }
        this.getLocalstorageUser();
      });
    }
    document.addEventListener("click", (e) => {
      var hideTag = e.target.tagName.toUpperCase();
      if (
        e.target.className != "recommend-content" &&
        e.target.className != "ant-input" &&
        e.target.className != "recommend-padding" &&
        e.target.className != "recommend-title" &&
        e.target.className != "recommend-title-more-box" &&
        e.target.className != "recommend-more-title" &&
        e.target.className != "recommend-box" &&
        e.target.className != "recommend-delete"
      ) {
        this.desitoryButtom = false;
        this.showRecommendSelect = false;
      }
    });
    if (window.localStorage.getItem("search")) {
      this.searchDataHistory = JSON.parse(
        window.localStorage.getItem("search")
      );
    }

    this.bus.$on("shoppingModel", (massage) => {
      this.ShowShoppingModel();
      this.showMsg = massage;
    });

    this.detectionVersion();
    // 第一次使用时间
    let firstUseTime = localStorage.getItem("firstUseTime");
    if (!firstUseTime) {
      localStorage.setItem("firstUseTime", Util.getCurrentTime());
      Config.activationBz();
    }
    this.registrationOnTheSecondDay();
    setTimeout(() => {
      this.checkUpgrade()
    }, 1500);
    
  },
  mounted() {
    this.getClickButton();
    this.getStorage(false);
    this.get_bodyWidth();
    this.getPublicContentHeight();
    this.getLeftNavigationWidth();
    var router = this.$router.history.current.name;
    if (window.performance.navigation.type == 1) {
    } else {
      if (router == "StormBookTag") {
        this.stormBookTagAccout = true;
        this.chipsPageAccout = false;
        this.homeBorderComponentAccout = true;
      } else {
        if (this.$router.history.current.name == "Home") {
          //如果是初次走入Home页面，需要跳转到最新导图页面。
          this.slider("MyMind", 1);
          this.clickButton = 0;
        }

        this.ieVersion(); //判断当前浏览器
      }
    }
    this.postCrispsListHotSearch();
    var that = this;
    document.addEventListener("visibilitychange", function () {
      if (document.visibilityState == "visible") {
        let userData = localStorage.getItem("meMessage");
        if (userData != null) {
          that.postUserMe();
          return;
        }
      }
    });
    window.addEventListener("popstate", (ev) => {
      //监听浏览器点击返后按钮
      ev.preventDefault();
      history.pushState(null, null, document.URL); //阻止浏览器的返后按钮
      this.reload();
      return;
    });
    if (this.nowElectron) {
      
      //判断当前状态是否是登录状态
      this.isLoginStatus();
      //mind+的菜单弹出的弹窗
      ipcRenderer.on("settingVal", (event, message) => {
        //联系我们
        if (message == "SettingUp") {
          this.showContactUs = true;
        }
      });
      ipcRenderer.on("about", (event, message) => {
        //关于
        if (message == "about") {
          this.showAboutProject = true;
        }
      });
      ipcRenderer.on("setUp", (event, message) => {
        //设置
        if (message == "setUp") {
          this.ShowSettingModel();
        }
      });
      ipcRenderer.on("help", (event, message) => {
        //帮助
        if (message == "help") {
          this.helpModal(true);
        }
      });
      //文件
      ipcRenderer.on("CreatedMind", (event, message) => {
        //创建
        if (message == "CreatedMind") {
          this.showCreatedMind = true;
        }
      });
      ipcRenderer.on("templateCreation", (event, message) => {
        //从模板创建
        if (message == "templateCreation") {
          // this.showCreatedMind = true;
          this.sliderMenuRouter(2);
        }
      });
      //我的

      ipcRenderer.on("personalInformation", (event, message) => {
        //查看个人信息
        //获取当前用户的数据
        if (message == "personalInformation" && localStorage.getItem("meMessage") != null) {
          let userMeData = JSON.parse(localStorage.getItem("meMessage"));
          this.showUserDataModel({
            accout: true,
            userId: userMeData.userId,
            xfrom: "crisps",
            fromId: userMeData.userId,
          });
        }
      });

      ipcRenderer.on("setInformation", (event, message) => {
        //设置信息
        if (message == "setInformation") {
          // this.helpModal(true);
          this.ShowBg = !this.ShowBg;
          this.userMessage = !this.userMessage;
        }
      });

      ipcRenderer.on("SignOut", (event, message) => {
        //退出登录
        if (message == "SignOut") {
          // this.helpModal(true);
          this.pushOutLogin();
          // this.isLoginStatus();
        }
      });
      ipcRenderer.on("GlobalLogin", (event, message) => {
        //登录注册
        if (message == "GlobalLogin") {
          // this.helpModal(true);
          this.ShowLoginModel();
          // this.isLoginStatus();
        }
      });

      ipcRenderer.on("judgeMDIFormLoginStatus", (event, message) => {
        //判断子页面和父页面的登录状态
        // console.log("judgeMDIFormLoginStatus", message);
        if (!message) {
          location.reload();
        }
      });

      ipcRenderer.send("close-loading-window", {
        isClose: true,
      });

      ipcRenderer.on("openExternalFiles", (event, json) => {
        if (json != null && json.length > 0 && json.toLowerCase().endsWith(".xjmm")) {
          new XJMindFIleImport(this, json, this.$router.appPath);
        } else {
          try {
            let jsonData = JSON.parse(json)
            if (jsonData.length > 0) {
              let openPath = ""
              for (let index = 0; index < jsonData.length; index++) {
                const path = jsonData[index];
                if (path != null && path.toLowerCase().endsWith(".xjmm")) {
                  openPath = path
                  break
                }
              }
              if (openPath != null && openPath.length > 0) {
                new XJMindFIleImport(this, openPath, this.$router.appPath);
              }
            }
          } catch (error) {
            console.log(error);
          }
        }
      });

      ipcRenderer.send("getOpenExternalFiles");
    }
    if (this.$route.path.includes("Home")) {
      document.title = this.$getStringObj.getString(
        this.$Strings.About_App_Name
      );
    }
  },
  methods: {
    ...mapMutations([
      "changeUserData",
      "ieVersion",
      "setUserIfLogin",
      "detailsToUserMessageModel",
      "refreshMyMindList",
      "setVersionUpdatingModal",
      "isShowShoppingModel",
      "setRefreshMyGroupList",
      "setGroupDataDetail",
      "helpModal",
      "showUserDataModel",
      "showLoginModel",
      "reloadStorageSpace",
    ]),
    getString(i) {
      return getString(i);
    },
    setStorage(res) {
      this.storage = res;
      this.showStorageInfo = res.showStorageInfo;
      if (this.storage.percent < 5) {
        this.storage.percent = 5;
      }
    },
    getStorage(accout) {
      postUserStorageInfo({}, (res) => {
        this.setStorage(res);
        if (!accout) {
          this.storageIsMeberModelAccout = res.showAlert;
        }
        //判断是否超出存储空间(24小时弹一次)
        // if (window.localStorage.getItem("storageModalShowTime") != null) {
        //   let time =
        //     Math.round(new Date() / 1000) -
        //     parseInt(window.localStorage.getItem("storageModalShowTime"));
        //   if (time / 60 / 60 > 24) {
        //     this.storageIsMeberModelAccout = res.showAlert;
        //     window.localStorage.removeItem("storageModalShowTime");

        //     window.localStorage.setItem(
        //       "storageModalShowTime",
        //       Math.round(new Date() / 1000)
        //     );
        //   }
        // } else {
        //   this.storageIsMeberModelAccout = res.showAlert;
        //   window.localStorage.setItem(
        //     "storageModalShowTime",
        //     Math.round(new Date() / 1000)
        //   );
        // }
      });
    },
    storageIsMeberCancelHandleOk() {
      this.storageIsMeberModelAccout = false;
    },
    storageIsMeberDeleteHandleOk() {
      this.storageIsMeberModelAccout = false;
      this.isShowShoppingModel(true);
    },
    postUserMe() {
      postUserMe(
        {},
        (res) => {
          doLogin(res);
          localStorage.setItem("meMessage", JSON.stringify(res));
        },
        (e) => {}
      );
    },

    detectionVersion() {
      this.versionUpdating({}).then((res) => {
        if (res.newVersion == "") {
          //当前是最新版本
          this.isLatestVersion = true;
        } else {
          //当前不是最新版本
          this.isLatestVersion = false;
          this.setVersionUpdatingModal(res);
        }
      });
    },

    versionUpdating(obj) {
      //检测版本更新
      let that = this;
      return new Promise((resolve, reject) => {
        postUserCheckUpdate(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            that.$message.error(error.desc);
            reject(error);
          }
        );
      });
    },

    //获取热门标签
    postCrispsListHotSearch() {
      postCrispsListHotSearch(null, (res) => {
        this.howRecommendMoreTitle = res;
      });
    },
    //获取焦点展开热门
    isShowRecommendSelect() {
      this.showRecommendSelect = this.showFocusRecommendSelect = true;
    },
    //搜索
    postCrispsSearch(id, page) {
      postCrispsSearch({ query: id, page: 1 }, (res) => {
        this.selectStormData = res;
        this.howRecommendMoreValue = id;
        this.showRecommendSelect = false;
      });
    },
    //输入搜索
    onSearch(value) {
      if (this.howRecommendMoreValue == "") {
        // this.howRecommendMoreValue = this.howRecommendMoreTitle.hotSearch;
        this.$router.push("/Home/Storm");
        return;
      } else {
        this.howRecommendMoreValue = value;
      }
      this.toStormSearch(2);
      sessionStorage.setItem("stormSearch", JSON.stringify(value));
    },
    toStormSearch(type) {
      if (this.howRecommendMoreValue == "") {
        // this.howRecommendMoreValue = this.howRecommendMoreTitle.hotSearch;
        this.$router.push("/Home/Storm");
        return;
      }
      sessionStorage.setItem(
        "stormSearch",
        JSON.stringify(this.howRecommendMoreValue)
      );
      let obj = { query: this.howRecommendMoreValue, type, page: 1 };
      postCrispsSearch(
        obj,
        (res) => {
          if (
            (res.crispsFeeds && res.crispsFeeds.length > 0) ||
            (res.groupFeeds && res.groupFeeds.length > 0)
          ) {
            this.$router.push({
              path: "/Home/StormSearch",
              name: "StormSearch",
              query: {
                id: this.howRecommendMoreValue,
                type,
              },
            });
            this.$message.success(
              getString(Strings.Message_Tips_Selecte_Success)
            );
            this.reload();
          } else {
            this.$message.warning(
              getString(Strings.The_Search_Result_Is_Empty)
            );
          }
        },
        (error) => {
          this.$message.warning(error.desc);
        }
      );
    },
    // onSearch(value) {
    //   if (this.howRecommendMoreValue == "") {
    //     this.howRecommendMoreValue = this.howRecommendMoreTitle.hotSearch;
    //     this.postCrispsSearch(this.howRecommendMoreTitle.hotSearch, 1);
    //   } else {
    //     this.postCrispsSearch(value, 1);
    //     this.howRecommendMoreValue = value;
    //   }
    //   for (let i = 0; i < this.searchDataHistory.length; i++) {
    //     const name = this.searchDataHistory[i].name;
    //     if (name == value || value == "") {
    //       return;
    //     }
    //   }
    //   this.searchDataHistory.push({ name: value });
    //   if (window.localStorage.getItem("search")) {
    //     window.localStorage.setItem(
    //       "search",
    //       JSON.stringify(this.searchDataHistory)
    //     );
    //   } else {
    //     window.localStorage.removeItem("search");
    //     window.localStorage.setItem(
    //       "search",
    //       JSON.stringify(this.searchDataHistory)
    //     );
    //     if (window.localStorage.getItem("search")) {
    //       this.searchDataHistory = JSON.parse(
    //         window.localStorage.getItem("search")
    //       );
    //     }
    //   }
    // },
    //删除历史记录显示
    isShowDesitoryData() {
      this.desitoryButtom = !this.desitoryButtom;
    },
    //删除历史搜索
    deleteDesitorySelect(index) {
      this.searchDataHistory.splice(index, 1);
      window.localStorage.removeItem("search");
      window.localStorage.setItem(
        "search",
        JSON.stringify(this.searchDataHistory)
      );
    },
    //跳转到 标签页 搜索内容页面
    recommendStormToTarget(id, type) {
      if (type == "STORM_TO_BOOKTAG") {
        if (this.nowElectron) {
          ipcRenderer.send(
            "openCalendarWindow",
            "/Home/StormBookTag/?id=" + id
          );
        } else {
          const newWindow = window.open();
          const routerData = this.$router.resolve({
            path: "/Home/StormBookTag/",
            query: {
              id: id,
            },
          });
          newWindow.location = routerData.href;
        }
      } else if (type == "STORM_TO_NEW_STORM") {
        this.postCrispsSearch(id, 1);
      }
    },

    //
    jumpOfficialWebsite(val) {
      // let a =  window.open('https://www.mindyushu.com/');
      // a.close();
      if (arguments.length == 1) {
        ipcRenderer.send("open-url", val);
      } else {
        ipcRenderer.send("open-url", "https://www.mindyushu.com/");
      }
    },
    getLocalstorageUser() {
      //获取localstorage中的用户信息
      if (!getJwt()) {
        this.meMessage = null;
        return;
      }
      let meMessage = localStorage.getItem("meMessage");
      if (!meMessage) {
        var self = this;
        postUserMe(
          {},
          (res) => {
            doLogin(res);
            self.getLocalstorageUser();
          },
          (e) => {}
        );
        return;
      }
      meMessage = JSON.parse(meMessage);
      this.meMessage = meMessage;
      let userMemberIsPermanent =
        parseInt(meMessage.memberExpire - meMessage.serverTime) / 86400; //获取会员天数
      if (userMemberIsPermanent >= 365 * 30) {
        //会员天数大于30年则是永久会员
        this.meMessage.memberExpire = getString(Strings.Permanent_Member);
      } else {
        this.meMessage.memberExpire = this.timeConversion(
          meMessage.memberExpire
        );
      }
      if (this.meMessage != null) {
        this.getImageUrl(meMessage.avatar);
        this.getStorage();
      }
    },
    clearLocalstorageUser() {
      localStorage.setItem("meMessage", "");
    },
    getImageUrl(avatar) {
      if (avatar == null || undefined) {
        return;
      }
      this.meMessage.avatar = HttpServerConfig.getMediaSrc(avatar);
    },
    isPageName() {
      let name = localStorage.getItem("pageLocation");
      if (name != null && name != undefined) {
        this.clickButton = localStorage.getItem("pageLocation");
      }
    },
    //消息
    ShowMessage() {
      this.$router.push("/Home/MyMessage/information");
    },
    ShowAnCement(val, type) {
      localStorage.setItem("pageLocation", val); //
      this.$router.push("/Home/MyMessage/announcement");
      if (val.key === "0") {
        this.backgroundColor = 0;
      } else if (val.key === "1") {
        this.backgroundColor = 1;
      }
      this.clickButton = val;
    },
    moment,
    changeLocale(localeval) {
      this.localeval = localeval;
      if (localeval === "en-us") {
        moment.locale("en-us");
        this.$i18n.locale = "en-us";
        this.locale = null;
      } else {
        moment.locale("zh-cn");
        this.$i18n.locale = "zh-cn";
        this.locale = zhCN;
      }
    },
    get_bodyWidth() {
      //动态获取浏览器高度
      const that = this;
      window.addEventListener("resize", () => {
        that.fullHeight = document.documentElement.clientHeight - 10;
        that.fullWidth = document.documentElement.clientWidth;
        that.bus.$emit("fullWidth", that.fullWidth);
        this.getPublicContentHeight();
      });
    },
    getSearch(k) {
      // 获取地址栏内容
      var str = window.location.href.split("?")[1];
      // 解码成中文
      str = decodeURIComponent(str);
      var arr = str.split("&");
      var obj = {};
      // 获取键和值
      arr.forEach(function (v, i) {
        var key = v.split("=")[0];
        var value = v.split("=")[1];
        obj[key] = value;
      });
      return obj[k];
    },
    jump(type, index) {
      //type为1时是切换页面内容，为2时是显示弹框
      if (type == 1) {
        // 避免重复点击同一个路由
        if (window.location.href.split("?")[0].split("/").pop() != index) {
          this.$router.push(index);
        }
        this.clickButton = index;
      } else if (type == 2) {
      }
    },
    sliderMenuRouter(index) {
      this.clickButton = index;
      this.showCreated = false;
      this.showTemplateCreated = false;
      if (index == 0) {
        this.$router.push("/Home/MyMind");
        this.homeBorderComponentAccout = false;
        this.stormBookTagAccout = false;
        this.chipsPageAccout = false;
      } else if (index == 1) {
        // // Strings.Group_Management
        this.$router.push("/Home/GroupManagement");
        this.chipsPageAccout = false;
        this.stormBookTagAccout = false;
        this.homeBorderComponentAccout = true;
      } else if (index == 2) {
        this.$router.push("/Home/MyModal");
        this.showTemplateCreated = true;
        this.showCreated = false;
        this.homeBorderComponentAccout = false;
        this.stormBookTagAccout = false;
        this.chipsPageAccout = false;
      } else if (index == 3) {
        this.$router.push("/Home/Storm");
        this.chipsPageAccout = true;
        this.stormBookTagAccout = false;
        this.homeBorderComponentAccout = true;
      } else if (index == 4) {
        this.setUserMessageDetails();
      }
    },
    slider(val, index) {
      if (val == "Setting") {
        this.ShowSettingModel();
        return;
      }
      if (val == "StudentCertification") {
        if (this.nowElectron) {
          ipcRenderer.send(
            "open-url",
            "https://www.mindyushu.com/studentcert.html"
          );
        } else {
          window.open("https://www.mindyushu.com/studentcert.html");
        }
        return;
      } else if (val == "Help") {
        if (this.nowElectron) {
          ipcRenderer.send(
            "open-url",
            "https://www.mindyushu.com/help1.html"
          );
        } else {
          window.open("https://www.mindyushu.com/help1.html");
        }
        return;
      } else if (val == "upgrade_vip") {
        this.ShowShoppingModel();
        return;
      }
      if (val == "InvitationCode") {
        let isLogin = this.loginStatus();
        if (isLogin) {
          this.showInvitedCode = true;
          return;
        } else {
          return;
        }
      }
      //让顶部的点击按钮去除选中
      //如果点击左边菜单栏的内容
      if (index === undefined) {
        this.clickButton = "";
        //判断是否切换颜色
        if (val.key === "0") {
          this.backgroundColor = 0;
        } else if (val.key === "1") {
          this.backgroundColor = 1;
        } else {
          // 避免重复点击同一个路由
          if (window.location.href.split("?")[0].split("/").pop() != val.key) {
            this.$router.push("/Home/" + val.key);
          }
        }
      } else if (index === 1) {
        //帮助也页面直接跳转到浏览器。
        // if (this.nowElectron && val == "Help") {
        //   ipcRenderer.send("open-url", "https://web.mindyushu.com/help");
        // } else {
        // 未登录导图数量超过1个弹登录，否则不能创建
        // const mapsNumber = localStorage.getItem("mapsNumber") || 0;
        // if (mapsNumber >= numberOfMapsTourist && !localStorage.getItem("meMessage")) {
        //   this.$store.state.showLogin = true;
        //   return;
        // }
        if (val == "CreatedFile") {
          this.showCreated = true;
          this.showTemplateCreated = false;
        } else {
          this.showCreated = false;
          this.showTemplateCreated = false;
        }
        //点击的是那三个按钮
        this.clickButton = val;
        localStorage.setItem("pageLocation", val); //
        this.$router.push("/Home/" + val);
        // this.$refs.visitedButton.$el.click();
        // }
      } else if (index === 2) {
        this.$router.push("/TestCanvas");
        //点击的是下载或者购物车
      } else {
      }
    },
    //设置模块
    ShowSettingModel() {
      this.setting = true;
      this.storageIsMeberModelAccout = false; //隐藏存储空间不足提示
      this.clearALLActive();
    },
    HideSettingModel() {
      this.setting = false;
      this.getClickButton();
    },
    //登录模块
    ShowLoginModel() {
      this.userModel = !this.userModel;
      this.ShowBg = !this.ShowBg;
      this.showLoginModel(true);
    },
    HideUserModel(userModel) {
      this.userModel = userModel;
      this.ShowBg = false;
    },
    //登录成功
    ShowHome(Account) {
      this.userModel = Account;
      this.ShowBg = Account;
      this.getLocalstorageUser();
    },
    //购物车模块
    ShowShoppingModel() {
      this.ShoppingModel = !this.ShoppingModel;
      this.ShowBg = !this.ShowBg;
      this.isShowShoppingModel(true);
    },
    HideShoppingModel(Account) {
      this.ShoppingModel = Account;
      this.ShowBg = false;
      this.showMsg = false;
      this.isShowShoppingModel(false);
    },
    shoppJumpLogin() {
      this.ShowLoginModel();
    },
    //退出登录
    pushOutLogin() {
      postUserLogout(
        {},
        (data) => {},
        (e) => {
          console.log("退出失败");
        },
        () => {
          this.getStorage(true);
          this.setUserIfLogin(false);
          doLogout();
          this.refreshMyMindList(true); //刷新我的导图界面
          //刷新群组页面信息
          this.setRefreshMyGroupList();
          this.setGroupDataDetail("noChangeData");
          if (this.nowElectron) {
            this.isLoginStatus();
          }
          this.reload();
        }
      );

      this.clearLocalstorageUser();
      // this.getLocalstorageUser();
    },
    //切换账号
    switchAccount() {
      this.showLoginModel(false);
      postUserLogout(
        {},
        (data) => {},
        (e) => {},
        () => {
          doLogout();
          if (this.nowElectron) {
            this.isLoginStatus();
          }
          // this.reload();
          this.setUserIfLogin(false);
          this.$nextTick(() => {
            this.showLoginModel(true);
          });
        }
      );
    },

    //设置用户信息
    setUserMessage() {
      this.ShowBg = !this.ShowBg;
      this.userMessage = !this.userMessage;
    },
    //查看用户信息
    setUserMessageDetails() {
      this.homeBorderComponentAccout = false;
      this.stormBookTagAccout = false;
      this.chipsPageAccout = false;
      this.clickButton = 4;
      this.$router.push({ path: "/Home/Details", name: "Details" });
    },
    //查看个人账户
    individualAccount() {
      this.homeBorderComponentAccout = false;
      this.stormBookTagAccout = false;
      this.chipsPageAccout = false;
      this.clickButton = null;
      this.$router.push({
        path: "/Home/individualAccount",
        name: "individualAccount",
      });
    },
    HideUserMessage(Account) {
      this.ShowBg = false;
      this.userMessage = Account;
      this.detailsToUserMessageModel(false);
    },
    clickAboutIcon() {
      this.showAboutProject = true;
    },
    showAboutModal(obj) {
      //隐藏弹框
      // if(obj.show == false) {
      this.showAboutProject = obj.show;
      // }
      if (obj.isVersion != undefined) {
        // obj.isVersion
        this.versionUpdating({}).then((res) => {
          //请求最新版本号
          // res = {
          //   newVersion:'新版本2.0.0',
          //   desc:'内容详情', //更新内容详情
          //   url:'https://r.mindyushu.com/dl/release/windows/mindmap_windows_v2.0.0_amd64.exe', //安装包下载链接
          //   isDirectDownloadLink: false //指明上面的Url是否是下载直链（如果为false，则需要跳转到浏览器手动点下载）
          // }
          if (res.newVersion == "") {
            //当前是最新版本
            this.isLatestVersion = true;
            this.$message.success("当前已是最新版本");
          } else {
            //当前不是最新版本
            this.isLatestVersion = false;
            this.setVersionUpdatingModal(res);
            this.showAboutProject = false; // 隐藏版本信息页面
          }
        });
      }
    },
    showContactModal(val) {
      this.showContactUs = val;
    },
    hideCreatedBox() {
      this.showCreatedMind = false;
    },
    //动态设置通用功能的高度
    getPublicContentHeight() {
      let publicContent = this.$refs.publicContent;
      if (publicContent) {
        let fullHeight = document.documentElement.clientHeight;
        if (fullHeight) {
          let publicContentHeight = fullHeight - 10 - 135 - 140 - 76;
          publicContent.style.height = publicContentHeight + "px";
        }
      }
    },
    isLoginStatus() {
      let languages = {
        eleAbout: getString(this.Strings.Mind_About),
        eleSetting: getString(this.Strings.Mind_Group_Setting_Up),
        eleHelp: getString(this.Strings.Mind_Edit_Left_Menu_Help),
        eleContactUs: getString(this.Strings.Setting_Up),
        eleFile: getString(this.Strings.Mind_File),
        eleNewlyBuild: getString(this.Strings.My_Mind_Create),
        eleTemplate: getString(this.Strings.TEMPLATE),
        eleMine: getString(this.Strings.Mind_Edit_Mine),
        eleSee: getString(this.Strings.See_Checking_Data),
        eleModify: getString(this.Strings.Modify_Checking_Data),
        eleSignOut: getString(this.Strings.Sign_Out),
        eleLogin:
          getString(this.Strings.User_Lolgin_Registered) +
          "/" +
          getString(this.Strings.User_Lolgin_Login),
        eleEdit: getString(this.Strings.Global_Edit),
        eleCut: getString(this.Strings.Mind_Shear),
        eleCopy: getString(this.Strings.Mind_Edit_Node_Menu_Copy),
        elePaste: getString(this.Strings.Mind_Edit_Node_Menu_Paste),
        eleSelectAll: getString(this.Strings.Mind_Group_Check_All),
      };
      if (!MindMe.isLogin()) {
        ipcRenderer.send("isSignIn", false, languages);
      } else {
        ipcRenderer.send("isSignIn", true, languages);
      }
    },
    getClickButton() {
      var router = this.$router.history.current.name;
      if (router == "MyMind") {
        this.clickButton = 0;
        this.routerNameIsStorm = false;
        this.showStorageRouter = true;
        this.myMindOverflow();
      } else if (router == "GroupManagement") {
        this.clickButton = 1;
        this.routerNameIsStorm = false;
        this.showStorageRouter = true;
        this.homeBorderComponentAccout = true;
        this.chipsPageAccout = false;
      } else if (router == "MyModal") {
        this.clickButton = 2;
        this.routerNameIsStorm = false;
        this.showStorageRouter = true;
      } else if (router == "Details") {
        this.clickButton = 4;
        this.stormBookTagAccout = false;
        this.chipsPageAccout = false;
        this.routerNameIsStorm = false;
        this.showStorageRouter = true;
      } else if (router == "Storm") {
        this.stormPage();
        this.clickButton = 3;
      } else if (router == "moreGroups") {
        this.moreGroupsPage();
      } else if (router == "moreCrisps") {
        this.moreCrispsPage();
      } else if (router == "tag") {
        this.stormTagPage();
      } else if (router == "StormSearch") {
        this.stormSearchPage();
      } else if (router == "StormBookTag") {
        this.stormBookTagAccout = true;
        this.chipsPageAccout = false;
        this.homeBorderComponentAccout = true;
        this.routerNameIsStorm = false;
        this.showStorageRouter = false;
      } else if (router == "GroupManagement") {
        this.stormBookTagAccout = false;
        this.chipsPageAccout = false;
        this.homeBorderComponentAccout = true;
        this.routerNameIsStorm = false;
        this.showStorageRouter = false;
      } else if (router == this.routerParameters.Archive) {
        this.clickButton = this.routerParameters.Archive;
        this.routerNameIsStorm = false;
        this.showStorageRouter = true;
      } else if (router == "Help") {
        this.clickButton = "Help";
        this.routerNameIsStorm = false;
        this.showStorageRouter = true;
      } else if (router == "CreatedFile") {
        this.clickButton = null;
        this.showCreated = true;
        this.showTemplateCreated = false;
      } else {
        this.showStorageRouter = true;
      }
    },
    closeAllCom() {
      this.chipsPageAccout = false;
      this.stormMoreGroups = false;
      this.stormMoreCrisps = false;
      this.stormTag = false;
      this.stormSearch = false;
      this.stormBookTagAccout = false;
      this.homeBorderComponentAccout = true;
      this.routerNameIsStorm = false;
      this.showStorageRouter = false;
      this.clickButton = null;
    },
    stormPage() {
      this.closeAllCom();
      this.chipsPageAccout = true;
      this.routerNameIsStorm = true;
    },
    moreGroupsPage() {
      this.closeAllCom();
      this.stormMoreGroups = true;
      this.routerNameIsStorm = true;
    },
    moreCrispsPage() {
      this.closeAllCom();
      this.stormMoreCrisps = true;
      this.routerNameIsStorm = true;
    },
    stormTagPage() {
      this.closeAllCom();
      this.stormTag = true;
      this.routerNameIsStorm = true;
    },
    stormSearchPage() {
      this.closeAllCom();
      this.stormSearch = true;
      this.routerNameIsStorm = true;
      if (this.stormSearch) {
        let stormSearch = JSON.parse(sessionStorage.getItem("stormSearch"));
        if (stormSearch) {
          //获取风暴搜索内容
          this.howRecommendMoreValue = stormSearch;
        }
      }
    },
    watchRouterChange(router) {
      if (router == "/Home/Help") {
        this.clickButton = "Help";
        this.log(this.clickButton);
      }
      if (router == "/Home/Storm") {
        this.stormPage();
        this.clickButton = 3;
      } else {
        this.stormMoreGroups = false;
      }
      if (router == "/Home/moreGroups") {
        this.moreGroupsPage();
      } else {
        this.stormMoreGroups = false;
      }
      if (router == "/Home/moreCrisps") {
        this.moreCrispsPage();
      } else {
        this.stormMoreCrisps = false;
      }
      if (router == "/Home/tag") {
        this.stormTagPage();
      } else {
        this.stormTag = false;
      }
      if (router == "/Home/StormSearch") {
        this.stormSearchPage();
      } else {
        this.stormSearch = false;
      }
      this.getClickButton();
    },
    timeConversion(tim) {
      return dataTimeToFormat.timestampToTime(tim);
    },
    //登录状态
    loginStatus() {
      if (!getJwt("jwt")) {
        this.showLoginModel(true);
        return false;
      } else {
        return true;
      }
    },
    // 游客第二日弹注册
    registrationOnTheSecondDay() {
      let firstUseTime = localStorage.getItem("firstUseTime");
      if (firstUseTime) {
        let time = Util.getCurrentTime() - firstUseTime;
        let useTime = time / 1000 / 60 / 60;
        if (useTime > 24) {
          var router = this.$router.history.current.name;
          if (router == "MyMind") {
            //首页弹注册
            let val = getCookie("newGuide"); //已完成新手引导
            // let val = localStorage.getItem("newGuide");
            if (val) {
              this.loginStatus();
            }
          }
          // console.log(useTime,time);
        }
      }
    },
    // 非会员第三天弹购买
    checkUpgrade() {
      if (MindMe.isUpgrade()) {
        return
      }
      let firstUseTime = localStorage.getItem("firstUseTime");
      if (firstUseTime) {
        let time = Util.getCurrentTime() - firstUseTime;
        let useTime = time / 1000 / 60 / 60;
        if (useTime > 24 * 2) {
          this.isShowShoppingModel(true);
        }
      }
    },
    // 已登录 弹付费窗 2分钟弹出一次
    showShoppingFiveMinutesOnce() {
      setInterval(() => {
        const meMessage = JSON.parse(localStorage.getItem("meMessage"));
        if (meMessage && !meMessage.isMember) {
          let showShoppingTime = localStorage.getItem("showShoppingTime");
          let nowTime = Util.getCurrentTime();
          if (showShoppingTime) {
            let time = nowTime - showShoppingTime;
            if (time > Config.REGISTER_POPUP_WINDOW_TIME) {
              this.$store.state.showShopping = true;
              localStorage.setItem("showShoppingTime", nowTime);
            }
          } else {
            localStorage.setItem("showShoppingTime", nowTime);
          }
        }
      }, Config.REGISTER_POPUP_WINDOW_TIME + 1000);
    },
    // 显示导入文件弹窗
    showFileImportFun() {
      // 未登录导图数量超过1个弹登录
      const mapsNumber = localStorage.getItem("mapsNumber") || 0;
      if (mapsNumber >= numberOfMapsTourist && !getJwt("jwt")) {
        this.showLoginModel(true);
        return;
      }
      this.showFileImportModal = true;
      this.clearALLActive();
    },
    turnOnScanCreation() {
      // 打开扫描创建
      if (!getJwt("jwt")) {
        this.showLoginModel(true);
      } else {
        this.bus.$emit(eventBusMethod.ScanCreationEvent);
      }
    },
    //隐藏导入文件弹窗
    hiddenFileImportModal() {
      this.showFileImportModal = false;
      this.getClickButton();
    },
    clearALLActive() {
      this.showCreated = false;
      this.showTemplateCreated = false;
      this.clickButton = null;
    },
    HideInvitedCodeModel() {
      this.showInvitedCode = false;
    },
    myMindOverflow() {
      //最近创作右键菜单需要超过左侧导航
      let router = this.$router.history.current.name;
      let mainContentChildren = this.$refs.mainContentChildren;
      if (mainContentChildren) {
        if (router == "MyMind") {
          mainContentChildren.style["overflow-y"] = "visible";
        } else {
          mainContentChildren.style["overflow-y"] = "scroll";
        }
      }
    },
    getLeftNavigationWidth() {
      //获取左侧导航宽度
      let box = this.$refs.leftNavigationBox.$el;
      if (box) {
        this.leftNavigationWidth = box.style.width;
      }
    },
    showLeftNavigation() {
      //显示隐藏左侧导航
      this.leftNavigation = !this.leftNavigation;
      if (this.leftNavigation) {
        this.getLeftNavigationWidth();
      } else {
        this.leftNavigationWidth = 0 + "px";
      }
    },
  },
  watch: {
    $route: {
      handler(to, from) {
        var router = to.path;
        this.currentRouter = router;
        if (router == "/Home/Storm") {
          this.routerNameIsStorm = true;
        } else {
          this.routerNameIsStorm = false;
        }
        if (router == "/Home/Storm" || router == "/Home/StormBookTag") {
          //"/Home/MyMind"
          this.showStorageRouter = false;
        } else {
          this.showStorageRouter = true;
        }
        this.watchRouterChange(router);
        this.myMindOverflow();
      },
      deep: true,
    },
    //活动模态框
    "$store.state.activtiyModel"(newModelAccout) {
      // this.ShowBg = newModelAccout; //不用这个遮罩层
    },
    "$store.state.userMessageModel"(newModelAccout) {
      if (newModelAccout) {
        this.setUserMessage();
      }
      this.getLocalstorageUser();
    },
    "$store.state.sharePotatoChips.accout"(newSharePotatoChips) {
      this.ShowBg = newSharePotatoChips;
      this.sharePotatoChips = newSharePotatoChips;
    },
    "$store.state.hideModel.accout"(newShareModelAccout) {
      this.ShowBg = newShareModelAccout;
      this.shareModelAccout = newShareModelAccout;
    },
    "$store.state.colletionNewFileModel.accout"(newColletionNewFileModel) {
      this.ShowBg = newColletionNewFileModel;
      this.myCollectionNewFileModel = newColletionNewFileModel;
    },
    "$store.state.colletionLimitFileModel.accout"(newColletionLimitFileModel) {
      this.ShowBg = newColletionLimitFileModel;
      this.myColletionLimitFileModel = newColletionLimitFileModel;
    },
    "$store.state.colltionMoveFile.accout"(newColltionMoveFile) {
      this.ShowBg = newColltionMoveFile;
      this.myColltionMoveFileModel = newColltionMoveFile;
    },
    "$store.state.showLogin"(newShowLogin) {
      if (!this.ShoppingModel) {
        //只显示购物车或登录一个弹框
        this.ShowBg = newShowLogin;
        this.userModel = newShowLogin;
        this.getLocalstorageUser();
      }
    },
    "$store.state.userModelData.accout"(newShowUserModelData) {
      this.userDataModel = newShowUserModelData;
      this.ShowBg = newShowUserModelData;
    },
    "$store.state.showShopping"(accout) {
      this.ShoppingModel = accout;
      this.ShowBg = accout;
    },
    //刷新存储空间
    "$store.state.refreshStorageSpace"(newVal) {
      if (newVal) {
        this.getRefreshStorage().then((res) => {
          this.setStorage(res);
        });
        this.reloadStorageSpace(false);
      }
    },
    "$store.state.isShowFileImportWindow"(newVal) {
      this.showFileImportFun()
    },
    
  },
  computed: {},
  destroyed() {
    localStorage.setItem("pageLocation", null);
  },
};
</script>
<style lang="less">
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  // transform: translateX(10px);
  opacity: 0;
}
.ant-tooltip-inner {
  background: #ffffff !important;
}
//存储空间
.storage-content {
  // position: fixed;
  // bottom: 30px;
  // right: 50px;
  font-size: 12px;
  color: #666;
  // background-color: #fff;
  // padding: 20px;
  // border-radius: 60px;
  // box-shadow: 0 4px 12px rgba(0, 0, 0, 15%);
  user-select: none;
  // max-width: 241px;
  // width: 100%;
  min-width: 200px;
  // height: 80px;
  display: flex;
  align-items: center;
  position: relative;
  height: 40px;
  margin-top: -15px;
  .storage-message {
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  .storage-progress {
    justify-content: center;

    .ant-progress-line {
      position: absolute;
      top: 29px;
      left: 21px;
      width: 158px;
      div {
        .ant-progress-outer {
          .ant-progress-inner {
            background-color: #e4e4e4;
          }
          .ant-progress-text {
            color: #666;
            opacity: 1;
          }
        }
      }
    }
  }
}
.product-down {
  .recommend-select {
    margin-right: 20px;
    position: relative;
    .ant-input-affix-wrapper {
      input {
        border-radius: 30px;
      }
      .ant-input-suffix {
        right: 0px;
        i {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          opacity: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            background: #fd492b;
            color: white;
          }
        }
      }
    }
    .recommend-change {
      position: absolute;
      top: 55px;
      left: 0px;
      width: 100%;
      // min-height: 374px;
      font-variant: initial;
      background-color: #fff;
      border-radius: 2px;
      outline: none;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      padding: 5px 0;
      .seach-content {
        padding: 0 12px;
        width: 100%;
        // height: 28px;
        line-height: 30px;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        // height: 40px;
        // margin-top: -15px;
        &:hover {
          color: #333;
          font-weight: 500;
          background-color: #fff5f0;
        }
      }
    }
    .recommend-content {
      position: absolute;
      top: 68px;
      right: 0px;
      width: 570px;
      min-height: 374px;
      background: #ffffff;
      box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.21);
      border-radius: 8px;
      .recommend-padding {
        padding: 30px 40px 0px 30px;
        .recommend-box {
          margin-bottom: 30px;
          .recommend-title {
            height: 25px;
            font-size: 18px;
            font-family: Noto Sans SC;
            font-weight: 400;
            line-height: 25px;
            color: #707070;
            opacity: 1;
            display: flex;
            justify-content: space-between;
            .recommend-delete {
              cursor: pointer;
            }
          }
          .recommend-title-more-box {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 10px;
            margin-top: 10px;
            max-height: 127px;
            height: 127px;
            overflow-y: auto;
            .recommend-more-title {
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 8px 14px;
              margin: 10px 18px 10px 0px;
              height: 36px;
              background: #f1f1f1;
              border-radius: 30px;
              color: #ec705a;
              position: relative;
              img {
                position: absolute;
                right: -5px;
                top: -3px;
              }
            }
          }

          .recommend-title-more-box::-webkit-scrollbar {
            width: 5px;
            height: 5px;
          }
          .recommend-title-more-box::-webkit-scrollbar-track {
            background: rgb(239, 239, 239);
            border-radius: 2px;
          }
          .recommend-title-more-box::-webkit-scrollbar-thumb {
            background: #bfbfbf;
            border-radius: 5px;
          }
          .recommend-title-more-box::-webkit-scrollbar-thumb:hover {
            background: #bfbfbf;
          }
        }
      }
    }
  }
  .recommend-focus-select {
    .ant-input-affix-wrapper {
      .ant-input-suffix {
        i {
          background: #fd492b;
          color: white;
        }
      }
    }
  }
}
@media screen and(min-width:250px) and(max-width:1400px) {
  .product-down {
    .recommend-select {
      margin-right: 20px;
      position: relative;
      .ant-input-affix-wrapper {
        width: 100% !important;
      }
    }
  }
}
</style>
<style scoped lang="less">
.bg-color-box {
  user-select: none;
}
ul li {
  list-style: none;
}
a {
  text-decoration: none;
}

.usermessage-father-box {
  position: fixed;
  z-index: 1200;
  width: 100%;
  margin: 0 auto;
  top: 50%;
  transform: translate(0%, -50%);
}

.share-pc {
  position: absolute;
  z-index: 1200;
  width: 100%;
  margin: 0 auto;
  top: 50%;
  transform: translate(0%, -50%);
}

.share-pc-move {
  position: absolute;
  z-index: 1100;
  width: 100%;
  margin: 0 auto;
  top: 50%;
  transform: translate(0%, -50%);
}

.account-no-login .ant-btn {
  /* background-color: #333 !important; */
  border: none;
  font-size: 16px;
  margin-top: 2px;
  /* width: 82px; */
  border: 2px solid rgba(255, 255, 255, 0);
  font-family: Noto Sans SC;
  font-weight: 400;
  border-radius: 19px;
  color: #fd492b;
}
.account-no-login .ant-btn:hover {
  color: #ff7354;
  /* background: #FFEAE6; */
  border: 2px solid #ff7354;
}
.account-no-login .ant-btn:focus {
  outline: none;
  color: #ffffff;
  background-color: #fd492b !important;
  border: none;
}
.ant-tooltip-open {
  /* background-color: #ffffff; */
  text-align: center;
}
.ant-message {
  z-index: 1300 !important;
}
.account-login {
  align-items: center;
  /* display: flex; */
  font-size: 16px;
  color: #333;
  cursor: pointer;
}
.account-login:hover {
  color: #ff765e;
}
.account-login .head_avatar {
  position: relative;
  /* margin-right: 10px; */
  margin-top: 12px;
  width: 200px;
  height: 71px;
}

.head_avatar_icon {
  position: absolute;
  left: 76px;
  top: 17px;
  z-index: 1;
}
.account-login .crown {
  position: absolute;
  left: 76px;
  top: 0;
  /* z-index: -1; */
  width: 48px;
  /* height: 42px; */
}
/*.user-data{
  width: ;
} */
.user-data p {
  text-align: center;
  font-size: 14px;
  font-family: Noto Sans SC;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
  /* margin: 0px; */
  padding: 0px;
}
/* .user-data p:hover {
   color: #ff765e; 
   background-color: #ddd; 
} */
/* .account-login img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
} */
.account-login span {
  font-size: 16px;
  color: #333;
}

.ant-tooltip-inner {
  min-width: 155px;
  min-height: 96px;
  line-height: 35px;
  color: #333333;
  background: #ffffff;
  text-align: center;
}
.bg-color {
  background-color: black;
  opacity: 0.5;
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
}
.Shopping-Father-Box {
  width: 100%;
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.user-model-icon {
  position: absolute;
}

/* .message-logo {
  margin-right: 50px;
  position: relative;
  cursor: pointer;
  margin-top: 6px;
} */
.message-header-logo {
  color: #fff;
  font-size: 20px;
  line-height: 64px;
  /* transition: 0.3; */
  -webkit-animation-name: scaleDraw; /*关键帧名称*/
  -webkit-animation-timing-function: ease-in-out; /*动画的速度曲线*/
  -webkit-animation-iteration-count: infinite; /*动画播放的次数*/
  -webkit-animation-duration: 5s; /*动画所花费的时间*/
}
@keyframes scaleDraw {
  /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
  0% {
    transform: scale(1); /*开始为原始大小*/
  }
  25% {
    transform: scale(1.2); /*放大1.1倍*/
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.2);
  }
}

.btn-bell-badge {
  position: absolute;
  /* right: -9px; */
  top: 14px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #f56c6c;
  color: #fff;
  --webkit-animation-name: scaleDraw; /*关键帧名称*/
  --webkit-animation-timing-function: ease-in-out; /*动画的速度曲线*/
  --webkit-animation-iteration-count: infinite; /*动画播放的次数*/
  --webkit-animation-duration: 5s; /*动画所花费的时间*/
}
@font-face {
  font-family: "iconfont";
  src: url("../../assets/font-icon/font_to4y6wnchti/iconfont.eot");
  src: url("../../assets/font-icon/font_to4y6wnchti/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/font_to4y6wnchti/iconfont.woff2")
      format("woff2"),
    url("../../assets/font-icon/font_to4y6wnchti/iconfont.woff") format("woff"),
    url("../../assets/font-icon/font_to4y6wnchti/iconfont.ttf")
      format("truetype"),
    url("../../assets/font-icon/font_to4y6wnchti/iconfont.svg#iconfont")
      format("svg");
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "iconfont1";
  src: url("../../assets/font-icon/font_y4dgzvzbp1/iconfont.eot");
  src: url("../../assets/font-icon/font_y4dgzvzbp1/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/font_y4dgzvzbp1/iconfont.woff2") format("woff2"),
    url("../../assets/font-icon/font_y4dgzvzbp1/iconfont.woff") format("woff"),
    url("../../assets/font-icon/font_y4dgzvzbp1/iconfont.ttf")
      format("truetype"),
    url("../../assets/font-icon/font_y4dgzvzbp1/iconfont.svg#iconfont")
      format("svg");
}
.iconfont1 {
  font-family: "iconfont1" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconfont1-text {
  position: relative;
  top: 2px;
  left: 3px;
  margin-right: 5px;
  font-size: 22px;
}

@font-face {
  font-family: "iconfontabout";
  src: url("../../assets/font-icon/font_z8sfmeugc3m/iconfont.eot");
  src: url("../../assets/font-icon/font_z8sfmeugc3m/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/font_z8sfmeugc3m/iconfont.woff2")
      format("woff2"),
    url("../../assets/font-icon/font_z8sfmeugc3m/iconfont.woff") format("woff"),
    url("../../assets/font-icon/font_z8sfmeugc3m/iconfont.ttf")
      format("truetype"),
    url("../../assets/font-icon/font_z8sfmeugc3m/iconfont.svg#iconfont")
      format("svg");
}
.iconfont-about {
  font-family: "iconfontabout" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* 头部开始 */
.header-box {
  /* background-color: rgba(249,139,160); */
  /* background-image: linear-gradient(
    to right,
    rgb(253, 51, 51),
    rgb(253, 85, 39)
  ); */
  padding: 0 !important;
  /* display:none; */
}
.header-top {
  height: 59px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  /* background-color: #fff; */
  position: absoulte;
  z-index: 5555;
  /* border-bottom: 2px #efefef solid; */
  background: #f5f5f5;
  border-bottom: 1px solid #ffffff;
  /* z-index: 10; */
  /* -moz-box-shadow:0px 2px 5px #A1A1A1;
  -webkit-box-shadow:0px 2px 5px #A1A1A1; */
  /* box-shadow:0px 2px 5px #A1A1A1; */
  /* box-shadow: 0px 3px 20px rgba(161, 161, 161, 0.5); */
}

.header-top .product-name .menu-title {
  height: 64px;
  position: absolute;
  left: 212px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1400px) {
  .header-top .product-name .menu-title {
    height: 64px;
    position: absolute;
    left: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.header-top .product-name .menu-title .addstyle {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid #fd492b;
  border-radius: 3px;
  margin-bottom: -39px;
}
.header-top .product-name .menu-title .addstyleColor {
  color: #fd492b;
}
.header-top .product-name .menu-title .menu-name {
  width: 90px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 22px;
  opacity: 1;
  margin: 0px 15px;
  flex-wrap: wrap;
}
.header-top .product-name .menu-title .menu-name-text {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
}
.header-top .product-name .menu-title .menu-name-text:hover {
  color: #fd492b;
  transition: 0.5s;
}

.header-top .product-name .menu-title .menu-name-text img {
  display: block;
  width: 22px;
  height: 22px;
  margin-right: 6px;
  margin-top: 0px;
}

.product-name {
  /* font-size: 16px;
  font-family: Noto Sans SC;
  font-weight: 400;
  color: #FD492B; */
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;
}
.product-name .official-website-img {
  cursor: pointer;
}
.product-name .content-header-text {
  font-size: 16px;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 22px;
  color: #333333;
  opacity: 1;
  margin-left: 10px;
  position: relative;
  top: 4px;
}
.product-down {
  display: flex;
  align-items: center;
}
.divshoping {
  font-size: 14px;
  height: 28px;
  margin-right: 15px;
  border-radius: 6px;
  border: 1px solid #fd492b;
  background-color: #f5f5f5;
  color: rgb(253, 51, 51);
}
.divshoping:hover {
  color: #ff7354;
  border-color: #ff7354;
}
.divshoping:active {
  position: relative;
  font-size: 16px;
  border-radius: 20px;
  background-color: #fff;
  color: rgb(253, 51, 51);
}

.header-icon-style {
  cursor: pointer;
}
/* 白色头部开始 */
.background-color-1 .header-bottom {
  /* background-color: rgb(32, 32, 32); */
  /* display: flex; */
  position: relative;
  width: 89%;
  float: right;
  /* padding-left: 250px; */
  /* justify-content: space-between; */
}
.header-bottom::before {
  content: "";
  width: 255px;
  height: 40px;
  margin-left: 570.1px;
  position: absolute;
  z-index: 2;
  /* background: url(../../assets/img/user/overbar6922.png)no-repeat content-box; */
}
.background-color-1 .header-bottom-child {
  width: 66px;
  text-align: center;
  font-size: 18px;
  height: 66px;
  /* line-height: 44px; */
  border-radius: 50%;
  background-color: #f3f4f7;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.18);
  margin-right: 1.6em;
  margin-left: 1.6em;
  margin-bottom: 10px;
  margin-top: 200px;
  color: #333;
  cursor: pointer;
}

.background-color-1 .header-bottom-child.clickButton {
  color: #fff;
  background-color: #fd492b;

  box-shadow: 0px 3px 6px #d12727;
}

.header-content-first {
  margin: 0 auto;
  display: block;
  display: flex;
}
.header-content-last {
  margin: 0 auto;
  margin-top: 10px;
  display: block;
  display: flex;
  margin-top: -40px;
}
.header-bottom-left {
  margin: 0 auto;
}
@media (min-width: 1800px) {
  .header-content-first {
    margin-left: 600px;
    /* display: flex; */
  }
  .header-content-last {
    margin-left: 541px;
    /* display: flex; */
  }
  .background-color-1 .header-bottom-left {
    margin: initial;
  }
}

.background-color-1 .header-bottom-left {
  height: 80px;
  display: flex;
  /* padding-left: 540px; */
  margin-top: 3.1em;
  /* margin: 0 auto; */
  align-items: flex-end;
  text-align: center;
  overflow: hidden;
  clear: both;
}
.background-color-1 .header-bottom-left .hbquan i::before {
  content: "";
  width: 12px;
  height: 12px;
  margin-left: 3.9em;
  margin-top: -94px;
  background: #fff;
  border: 1px solid #cccccc;
  border-radius: 50%;
  position: absolute;
  z-index: 2;
  opacity: 1;
}
.background-color-1 .header-bottom-left .hbquan .clickquanquan::before {
  border: 1px solid #fd492b;
  background-color: #fd492b;
  /* color:#FD492B; */
}
.background-color-1 .header-bottom-left .hbquan .quanquan2::before {
  content: "";
  margin-left: 12.8em;
}
.background-color-1 .header-bottom-left .hbquan .quanquan3::before {
  content: "";
  margin-left: 21.74em;
}
.background-color-1 .header-bottom-right {
  display: flex;
  align-items: center;
  margin: 0px 15px;
}
.background-color-1 .header-bottom-right .shoping-car {
  display: block;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  cursor: pointer;
}

/* 白色头部结束 */

/* 黑色头部开始 */
.background-color-2 .header-bottom {
  position: relative;
  width: 89%;
  float: right;
}
.background-color-2 .header-bottom-child {
  width: 66px;
  text-align: center;
  font-size: 18px;
  height: 66px;
  /* line-height: 44px; */
  border-radius: 50%;
  background-color: #ededef;
  margin-right: 3.2em;
  color: #333;
  cursor: pointer;
}
.background-color-2 .header-bottom-child:hover {
  background-color: #999;
}
.background-color-2 .header-bottom-child.clickButton {
  color: #333;
  background-color: #cec9c9;
}
.background-color-2 .header-bottom-left {
  height: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* padding-left: 540px; */
  margin-top: 3.1em;
  /* margin: 0 auto; */
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  text-align: center;
  overflow: hidden;
  clear: both;
}
.background-color-2 .header-bottom-right {
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-right: 75px;
}

.background-color-2 .header-bottom-right .shoping-car {
  display: block;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  cursor: pointer;
}
/* 黑色头部结束 */

/* 头部结束 */

/* 导航栏开始 */
/* 用于在点击头部按钮时隐藏之前导航菜单的选中 */
/* 新的导航栏开始 */
/* 修改默认框架的颜色 */
.bg-color-box .ant-layout-sider {
  background-color: #f5f5f5;
}
.sider-box1 {
  position: relative;
  background-color: #f5f5f5;
  color: #515151;
  border-right: 1px solid #e8e8e8;
  overflow-x:hidden;
  /* -moz-box-shadow: 0px 0px 5px #a1a1a1;
  -webkit-box-shadow: 0px 0px 5px #a1a1a1;
  box-shadow: 3px 6px 20px rgba(161, 161, 161, 0.5); */
}
.ant-layout-sider-children {
  border-right: 1px solid #ffffff;
}

.slider-mind-created-about {
  padding-top: 20px;
  height: 130px;
  font-size: 13px;
  // border-bottom: 1px solid #d0d0d0;
}
.created-each-box {
  position: relative;
  margin: 0 auto;
  width: 180px;
  height: 34px;
  line-height: 32px;
  /* text-align: center; */
  cursor: pointer;
  transition: 0.5s;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 6px;
  /* background: #e8e8e8; */
}
.created-each-box-img {
  margin-left: 40px;
  margin-right: 8px;
  width: 15px;
}
.archive-img {
  display: inline-block;
  margin: -2px 10px 0 0;
}
/* .created-default {
  background: #cccccc;
  color: #fff;
  border: transparent;
  transition: 0.5s;
} */
.created-each-box-selected {
  border: transparent;
  background-color: #d5d5d5;
  color: #fd492b;
  transition: 0.5s;
  /* border: 1px solid #fd492b; */
}

/* .created-each-box .created-each-box-gray { */
/* position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none; */
/* } */
.created-each-box:hover {
  display: block;
  background-color: #d5d5d5;
}
.created-each-box:active {
  display: block;
  background-color: #c0c0c0;
}

/* 中间部分 */
.mind-public-title {
  height: 56px;
  background-color: #f0f2f8;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 56px;
  color: #333;
  display: flex;
  justify-content: space-between;
}
.mind-public-title-icon {
  cursor: pointer;
}
.mind-public-content {
  position: relative;
  font-size: 14px;
  height: 310px;
  overflow-y: auto;
}
.mind-public-content::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  display: none;
}
.mind-public-content::-webkit-scrollbar-track {
  background: rgb(239, 239, 239);
  border-radius: 2px;
}
.mind-public-content::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 5px;
}
.mind-public-content::-webkit-scrollbar-thumb:hover {
  background: #bfbfbf;
}
.mind-public-content .left-backgund-img {
  position: absolute;
  left: 27px;
}

/* 所有选择的公共部分 */
.mind-public-content .right-check-public {
  position: relative;
  height: 33px;
  line-height: 30px;
  cursor: pointer;
  margin-top: 8px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 6px;
}

/* 图标 */
.mind-public-content .right-check-public .iconfont1-slider {
  font-size: 16px;
  margin-left: 40px;
  margin-right: 8px;
  width: 15px;
}

.mind-public-content .right-check-public .slider-icon-name {
  position: relative;
  font-size: 13px;
  top: 2px;
}

.mind-public-content .right-check-public .before-circle {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border-radius: 50%;
}
.mind-public-content .right-check-public .icon-active {
  display: none;
}
.background-color-1 .header-bottom-child:hover {
  color: #fff;
  background-color: #ff7354;
}
.mind-public-content .right-check-public:hover {
  background: #d5d5d5;
  border-radius: 6px;
  color: #ff7354;
}
.mind-public-content .right-check-public:hover .icon {
  display: none;
}
.mind-public-content .right-check-public:hover .icon-active {
  display: inline-block;
}
/* .mind-public-content .right-check-public:hover .before-circle {
  border: 1px solid #FD492B;
  background-color: #FD492B;
} */

.mind-public-content .right-check-public-selected {
  color: #fd492b;
  background: #d5d5d5;
}
.mind-public-content .right-check-public-selected .before-circle {
  border: 1px solid #fd492b;
  background-color: unset;
}
.mind-public-content .right-check-public-selected .icon {
  display: none;
}
.mind-public-content .right-check-public-selected .icon-active {
  display: inline-block;
}
/* .mind-public-content .right-check-item1 {
  left: 58px;
  top: 11px;
}
.mind-public-content .right-check-item2 {
  left: 58px;
  top: 64px;
}
.mind-public-content .right-check-item3 {
  left: 58px;
  top: 117px;
}
.mind-public-content .right-check-item4 {
  left: 58px;
  top: 170px;
}
.mind-public-content .right-check-item5 {
  left: 58px;
  top: 224px;
}
.mind-public-content .right-check-item6 {
  left: 58px;
  top: 276px;
} */

.slider-mind-footer-about {
  position: absolute;
  bottom: 0px;
  width: 200px;
  line-height: 40px;
  text-align: center;
  background-color: #efefef;
  cursor: pointer;
  font-size: 16px;
}
.slider-mind-footer-about-PC {
  height: 86px;
}
.slider-mind-footer-about-web {
  height: 76px;
}
.slider-mind-footer-about-gray {
  display: none;
}
.slider-mind-footer-about:hover .slider-mind-footer-about-gray {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}
.slider-mind-footer-about:active .slider-mind-footer-about-gray {
  background-color: rgba(0, 0, 0, 0.2);
}
/* 下载手机端 */
.down-coumputer {
  color: #999999;
  cursor: pointer;
}
.down-coumputer-PC {
  margin-top: 6px;
}
.down-coumputer-web {
  margin-top: 0;
}

.down-text {
  line-height: 20px;
}
.down-text-PC {
  margin-top: 12px;
}
.down-text-web {
  margin-top: 6px;
}

.down-coumputer .dc-platform {
  display: flex;
  justify-content: space-evenly;
  padding: 0px 10px;
}
/* .down-coumputer ul li a img {
  width: 27px;
  height: 27px;
} */

.dc-platform .dp-from .fushang .fs-shangM {
  position: absolute;
  margin: -9em 0em 0em -2em;
  z-index: 10;
}
.dc-platform .dp-from .fushang .kuang-beijingImg {
  margin: -9em 0em 0em -2em;
  position: absolute;
  z-index: 5;
}
.dc-platform .dp-from .fushang .fs-azshangM {
  position: absolute;
  margin: -9em 0em 0em -3.2em;
  z-index: 10;
}
.dc-platform .dp-from .fushang .kuang-azbeijingImg {
  margin: -9em 0em 0em -3.2em;
  position: absolute;
  z-index: 5;
}
.dp-from .fushang {
  display: none;
}
.dp-from:hover .fushang {
  display: initial;
}

/* 新的导航栏结束 */
/* 导航栏结束 */

/* 白色背景和底部开始 */
.background-color-1 .main-content {
  /* padding-top: 128px; */
  padding-left: 200px;
  /* padding-right: 20px; */
  /* height: 100vh; */
  background-color: #fff;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: rgb(239, 239, 239);
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #bfbfbf;
  }
}
.background-color-1 .main-content-children-box {
  /* overflow-y: hidden; */
  /* border-radius: 24px; */
  height: 100%;
  background-color: #fff;
  min-width: 865px;
}
.background-color-1 .main-content-children {
  overflow-y: scroll;
  /* padding: 12px; */
  height: 880px;
  /* overflow-x: hidden; */
}
.background-color-1 .main-content-children::-webkit-scrollbar {
  width: 6px;
}
.background-color-1 .main-content-children::-webkit-scrollbar-track {
  background: rgb(239, 239, 239);
  border-radius: 2px;
}
.background-color-1 .main-content-children::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 5px;
}
.background-color-1 .main-content-children::-webkit-scrollbar-thumb:hover {
  background: #bfbfbf;
}

.background-color-1 .footer-box {
  position: fixed;
  width: 100%;
  /* height: 32px; */
  background-color: #eee;
  bottom: 0;
}

.header-bottom1 {
  position: absolute;
  top: 200px;
  width: 370px;
  height: 150px;
  background-color: green;
  left: 50%;
  /* margin-left: 100px; */
  transform: translate(-50%, 0);
}

.fiery_start {
  float: right;
  margin-top: 8.5px;
  margin-right: 20px;
}
.login_out {
  border-bottom: 1px solid #666;
}
.login_out:hover {
  border-bottom: 1px solid #ff765e;
  color: #ff765e;
}

@media (min-width: 1800px) {
  .header-bottom1 {
    position: absolute;
    left: 300px;
  }
}
@media (max-height: 788px) and (min-height: 500px) {
  .Shopping-Father-Box {
    top: 60%;
  }
  .mind-public-content {
    height: 286px;
  }
}

/* 白色背景和底部结束 */

/* 黑色背景和底部开始 */
.background-color-2 .main-content {
  /* padding-top: 50px; */
  padding-left: 200px;
  background-color: #333;
}
.background-color-2 .main-content-children-box {
  overflow: hidden;
  /* border-radius: 24px; */
  height: 100%;
  background-color: #fff;
}

.background-color-2 .main-content-children {
  overflow-y: scroll;
  padding: 15px;
  overflow-x: hidden;
  .header-text {
    font-weight: bold;
  }
}
.background-color-2 .footer-box {
  position: fixed;
  width: 100%;
  /* height: 32px; */
  background-color: #333;
  bottom: 0;
}
/* 黑色背景和底部结束 */

.height {
  height: 1000px;
}

@page {
  size: auto; /* auto is the initial value */
  margin: 0mm; /* this affects the margin in the printer settings */
}
.setting-bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
}
.color-deepen {
  color: #212121;
}
</style>
