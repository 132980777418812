<template >
    <div class="invited-code-modal" @click.stop="cklickModal(false)" v-show="tagModalShow">
        <div class="invited-code-modal-box">
        <div class="invited-code-footer-sava" @click.stop="affirmSave">
                <!-- <div  @click.stop="affirm(true)" class="group-tap-footer-button" style="cursor:pointer"> -->
                  <img class="invited-code-footer-sava-img"  src="../../assets/img/groupImg/shareSave.png" alt="">
                   <span class="invited-code-footer-sava-span" >
                     {{ $getStringObj.getString($Strings.Mind_Group_Save) }}</span>
                <!-- </div> -->
        </div>
        <div class="invited-code-close" @click.stop="cklickModal(false)">
                <a-icon type="close-circle" :style="{ fontSize: '16px' }" />
        </div>
        <div class="invited-code-center-shera" onClick="event.cancelBubble = true">
            <div class="invited-code-center-bg-top">
                
                <div class="invited-code-list">
                    <div class="invited-code-name" >
                        <img src="../../assets/img/invitedCodeImg/LOGO.png"/>
                        <span style="top: 2px;position: relative;">&nbsp;&nbsp;{{ $getStringObj.getString($Strings.About_App_Name) }}</span>
                    </div>
                </div>
                <div class="invited-code-head-avatar">
                    <img
                        class="head_avatar_icon" :src="imgSRC"
                        :style="
                          'width: 64px; height: 64px; border-radius: 50%; overflow:hidden;'
                        "
                      />
                </div>
                <div class="invited-code-username">
                    <span>{{meMessage.username}}</span>
                </div>
                <div class="invited-code-motto">
                    <span>{{meMessage.motto}}</span>
                </div>
                <div class="invited-code-copy">
                    <div class="invited-code-copy-frame">
                        <p style="height: 13px;
                            font-size: 14px;
                            font-family: PingFang SC;
                            font-weight: 400;
                            color: #3A3434;
                            margin-bottom: 0;
                            margin-top: 21px;
                            line-height: 12px;">
                            {{ $getStringObj.getString($Strings.Invitation_Code) }}</p>
                        <p style="
                            font-size: 28px;
                            font-family: PingFang SC;
                            font-weight: 800;
                            color: #3A3434;
                            margin-top: 0px;
                            margin-bottom: 0;">{{invitedCodeMeassge.code}}</p>
                        <p style="font-size: 14px;
                            font-family: PingFang SC;
                            font-weight: 400;
                            margin-bottom: 0;
                            color: #666666;cursor: pointer;" @click.stop="copyInvitedCode">{{$getStringObj.getString($Strings.Mind_Edit_Node_Menu_Copy)}}{{ $getStringObj.getString($Strings.Invitation_Code) }}</p>
                    </div>
                </div>
                <div class="invited-code-qr-code">
                    <div class="invited-code-qr-code-url">
                        <vue-qr
                            :logoSrc="logoSrc"
                            :logoScale="0.25"
                            :text="'https://www.mindyushu.com/downloads.html'"
                            :size="64"
                            :correctLevel="0"
                            :margin="0"
                        ></vue-qr>
                    </div>
                    <div class="invited-code-qr-code-introduce1">
                        <span>超级记忆术 知识创作、梳理、笔记</span>
                    </div>
                    <div class="invited-code-qr-code-introduce2">
                        <span>头脑风暴 尽在寻简思维导图</span>
                    </div>
                    <!-- <div id="latexTest"></div> -->
                </div>
            </div>
            <div class="invited-code-center-btm-frame">
                <div class="invited-code-good-friend">
                    <div class="invited-code-people">
                        <img class="" src="../../assets/img/invitedCodeImg/good_friend.png"/>
                        <span class="invited-code-count">{{invitedCodeMeassge.childrenNum}}</span>
                        <span class="invited-code-people-one">人</span>
                        <span class="invited-code-people-good-friend" :style="{'marginLeft': (-15*(invitedCodeMeassge.childrenNum>=10?(invitedCodeMeassge.childrenNum>=100?3:2):1)) +'px'}">共邀请好友</span>
                    </div>
                    <div class="invited-code-vip">
                        <img class="" src="../../assets/img/invitedCodeImg/vip.png"/>
                        <span class="invited-code-count">{{invitedCodeMeassge.reward}}</span>
                        <span class="invited-code-people-one">个月</span>
                        <span class="invited-code-people-good-friend" :style="{'marginLeft': (-15*(invitedCodeMeassge.reward>=10?(invitedCodeMeassge.reward>=100?3:2):1))+'px'}">共获得会员</span>
                    </div>
                </div>
            </div>
            <div class="invited-code-center-bottom">
                <span class="invited-code-explain">邀请说明</span>
                <span class="invited-code-explain-one">{{invitedCodeMeassge.desc}}</span>
                <!-- <span class="invited-code-explain-tow">2.好友使用邀请码注册，购买会员享受9折优惠。</span> -->
            </div>
      </div>
      </div>
  </div>
</template>

<script>
import vueQr from "vue-qr";
import html2canvas from "html2canvas"
import imageToStyle from '../../utils/ImageToolkit';
import httpImageToPrefix from '../../utils/httpImageToPrefix';
import { postUserMyInvitation } from "../../common/netWork/base_api";
import Strings from "../../common/lang/strings";
import getString from "../../common/lang/language";
import UiUtil from '../../utils/UiUtil';
import Util from '../../utils/Util';
import MindMe from '../../viewmodel/facade/MindMe';
// import domtoimage from 'dom-to-image';
// import katex from "katex"

export default {
     props: [
        "show",
    ],
    data() {
        return {
            Strings:Strings,
            tagModalShow: true,
            confrim: false,
            meMessage:null,
            logoSrc: require("../../assets/img/groupImg/qrCodeLog.png"),
            imgSRC: null,
            invitedCodeMeassge:[],
        }
    },
    components: {
        vueQr,
        html2canvas,
        // domtoimage,
    },
    created(){
        this.meMessage = MindMe.getMeMessage();
        
    },
    mounted(){
        setTimeout(()=>{
            this.imgBase()
            this.getInvitedCodeMeassge();
            // this.testLatex();
        },100)
    },
    methods: {
        imgBase(){
            let imgSRC1=httpImageToPrefix(this.meMessage.avatar);
            new imageToStyle().webToBase64(imgSRC1).then(imageBase64 => {
//                 this.photo.setAttribute("src", imageBase64)
                this.imgSRC=imageBase64;
            })

        },
        getInvitedCodeMeassge(){

            return new Promise((resolve, reject) => { 
                postUserMyInvitation({},(res)=>{
                    this.invitedCodeMeassge = res
                },(err)=>{

                });
            })
        },
        cklickModal(val) {
            if (val == false) { //当隐藏模态框时
                this.hiddenTap({show:false, confrim: this.confrim})
            }
        },
        hiddenTap(obj) {
            this.$emit("HideInvitedCodeModel", obj);
        },
        affirmSave() {
            var downloadName = this.meMessage.username;
            var canvas2 = document.createElement("canvas");
            let _canvas = document.querySelector('.invited-code-center-bg-top');
            // let img = document.querySelector('.group-shera-img');
            var w = parseInt(window.getComputedStyle(_canvas).width);
            var h = parseInt(window.getComputedStyle(_canvas).height);
            var context = canvas2.getContext("2d");

            let ratio = (Util).getPixelRatio(context)
            canvas2.width = w*ratio;
            canvas2.height = h*ratio;
            canvas2.style.width = w + "px";
            canvas2.style.height = h + "px";
            
                // context.scale(2,2);
                html2canvas(document.querySelector('.invited-code-center-bg-top'), { canvas: canvas2 }).then(function(canvas) {

                     var imgUri = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"); // 获取生成的图片的url
                    // console.log(imgUri);
                        let link = document.createElement('a');
                        link.setAttribute("href", imgUri);

                        var timestamp = (new Date()).valueOf();
                        // if(this.shareList != undefined && this.shareList != null ){
                            downloadName = downloadName+'.png';
                        // }else{
                        //      downloadName = timestamp+'.png';
                        // }

                        
                        link.setAttribute("download", downloadName);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);

                });
                
                // this.cklickModal(false);


        },
        // affirmSave1(){
        //      const that = this
        //     const node = document.getElementById('invited-code-center-bg-top-img') // 通过id获取dom
        //     console.log(node, 'node')
        //     console.log(domtoimage, 'domtoimage')
        //     domtoimage
        //         .toPng(node,{ quality: 0.95 })
        //         .then((dataUrl) => {
        //         //输出图片的Base64,dataUrl
        //             // 下载到PC 
        //         console.log(dataUrl);
        //         // const a = document.createElement('a') // 生成一个a元素
        //         // const event = new MouseEvent('click') // 创建一个单击事件
        //         // a.download = 'xxxx' // 设置图片名称没有设置则为默认
        //         // a.href = dataUrl // 将生成的URL设置为a.href属性
        //         // a.dispatchEvent(event) // 触发a的单击事件
        //         })
        //         .catch(function (error) {
        //         console.error('oops, something went wrong!', error)
        //         })
        // },
        testLatex(){
            let latexDiv = document.getElementById("latexTest");

            let html = katex.renderToString('(1+x)^{n} = 1+\\frac{n x}{ 1! } + \\frac {n(n-1)x^{2}}{2!} + \\cdots', {
                throwOnError: false
            });
            latexDiv.innerHTML = html;
        },
        copyInvitedCode(){
            var input = document.createElement("textarea"); // 直接构建input
            input.value = this.invitedCodeMeassge.code; // 设置内容 需要复制的内容
            document.body.appendChild(input); // 添加临时实例
            input.select(); // 选择实例内容
            document.execCommand("Copy"); // 执行复制
            document.body.removeChild(input); // 删除临时实例
            this.$message.success(this.$getStringObj.getString(this.Strings.Message_Tips_Copy_Success));
        },
    },
    watch: {
        show: {
            handler(newval, olval) {
                this.tagModalShow = newval.showShare;
                if(newval.sheraList != null){

                  this.shareList = newval.sheraList;
                //   this.imgBase();
                     
                
                }
            },
            deep: true,
        }
    }
}
</script>

<style lang="less">
    .invited-code-modal {
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 999;
        background-color: rgba(0,0,0,.3);
        .invited-code-modal-box{
            position: absolute;
            z-index: 12;
            width: 680px;
            height: 600px;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
             .invited-code-close{
                position: absolute;
                font-size: 16px;
                color: #ffffff;
                width: 16px;
                height: 16px;
                cursor: pointer;
                z-index: 13;
                margin-left: 648px;
                margin-top: 10px;
            }
            .invited-code-footer-sava {
              
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                width: 77px;
                border-radius: 4px;
                height: 37px;
                z-index: 13;
                margin-left: 554px;
                margin-top: 18px;
                background-color: #ff5323;
                cursor: pointer;
                .invited-code-footer-sava-img{
                    width: 12px;
                    height: 13px;
                    margin-left: 15px;
                }
                .invited-code-footer-sava-span{
                    font-size: 14px;
                    width: 45px;
                    // height: 24px;
                    color: rgb(255, 255, 255);
                    margin-left: 7px;
                    line-height: 24px;
                    margin-top: -2px
                }
                .invited-code-footer-sava-span:hover {
                    // background-color: #FD492B;
                    // color: #ada9a9;
                }
            }
            .invited-code-footer-sava:hover{
                background-color: #ff3700;
            }
        .invited-code-center-shera {
            position: absolute;
            // overflow: hidden;
            z-index: 12;
            width: 680px;
            height: 600px;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            background: #ffffff;
            border-radius: 10px;
            // border: 1px solid #ededed;
            .invited-code-center-bg-top{
                width: 680px;
                height: 365px;
                background: url(../../assets/img/invitedCodeImg/invited_code_bg.png);
                position: relative;
            
                .invited-code-list {
                    position: relative;
                    width: 680px;
                    height: 44px;
                    line-height: 40px;
                    .invited-code-name {
                    position: absolute;
                        width: 400px;
                        height: 25px;
                        font-size: 14px;
                        color: #ffffff;
                        margin-left: 33px;
                        overflow: hidden;
                        display: inline-table;
                    }
                    .invited-code-desc {
                    position: absolute;
                        width: 400px;
                        height: 14px;
                        font-size: 16px;
                        color: #ffffff;
                        margin-top: 25px;
                        margin-left: 33px;
                        overflow: hidden;
                        display: inline-table;
                    }
                }
                .invited-code-head-avatar{
                    position: relative;
                    width: 680px;
                    height: 68px;
                    margin-top: -10px;
                    text-align: center;
                }
                .invited-code-username{
                    position: relative;
                    width: 680px;
                    height: 20px;
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 20px;
                }
                .invited-code-motto{
                    position: relative;
                    width: 680px;
                    height: 20px;
                    text-align: center;
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #FFD4D4;
                    text-align: center;
                    line-height: 20px;
                }
                .invited-code-copy{
                    position: relative;
                    width: 680px;
                    height: 120px;
                    margin-top: 5px;
                    text-align: center;
                    .invited-code-copy-frame{
                        position: absolute;
                        width: 353px;
                        height: 115px;
                        background: #FFFFFF;
                        // box-shadow: 0px 1px 10px 0px #84190646;
                        border-radius: 10px;
                        margin-left: 163.5px;
                        border: 1px solid #ededed;
                    }
                }
                .invited-code-qr-code{
                    position: relative;
                    width: 680px;
                    height: 74px;
                    text-align: center;
                    .invited-code-qr-code-url{
                        position: absolute;
                        width: 64px;
                        height: 64px;
                        margin-left: 163.5px; //125px;
                        margin-top: 12px;
                    }
                    .invited-code-qr-code-introduce1{
                        position: absolute;
                        margin-left: 243.5px;
                        margin-top: 27px;
                        font-size: 12px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: #333333;
                    }
                    .invited-code-qr-code-introduce2{
                        position: absolute;
                        margin-left: 243.5px;
                        margin-top: 50px;
                        font-size: 12px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: #333333;
                    }
                }
            }
            .invited-code-center-btm-frame{
                width: 680px;
                position: relative;
                margin-top: 30px;
                .invited-code-good-friend{
                    position: relative;
                    width: 427px;
                    height: 101px;
                    background: #FFFFFF;
                    border: 1px solid #FFB9AC;
                    border-radius: 10px;
                    box-shadow: 0px 0px 10px #FFB9ACB5 inset;
                    margin-left: 127px;
                    .invited-code-people{
                        position: absolute;
                        margin-top: 20px;
                        margin-left: 30px;
                        width: 213px;
                        .invited-code-count{
                            font-size: 30px;
                            color: #FD5638;
                            position: relative;
                            margin-left: 15px;
                            margin-top: -5px;
                        }
                        .invited-code-people-one{
                            color: #333333;
                            font-size: 14px;
                            position: absolute;
                            margin-left: 4px;
                            margin-top: 15px;
                        }
                        .invited-code-people-good-friend{
                            position: absolute;
                            font-size: 14px;
                            font-family: PingFang SC;
                            font-weight: 400;
                            color: #999999;
                            margin-top: 40px;
                        }
                    }
                    .invited-code-vip{
                        position: absolute;
                        margin-top: 20px;
                        margin-left: 252px;
                        width: 213px;
                        .invited-code-count{
                            font-size: 30px;
                            color: #FD5638;
                            position: relative;
                            margin-left: 15px;
                            margin-top: -5px;
                        }
                        .invited-code-people-one{
                            color: #333333;
                            font-size: 14px;
                            position: absolute;
                            margin-left: 4px;
                            margin-top: 15px;
                        }
                        .invited-code-people-good-friend{
                            position: absolute;
                            font-size: 14px;
                            font-family: PingFang SC;
                            font-weight: 400;
                            color: #999999;
                            margin-top: 40px;
                        }
                    }
                }
            }
            .invited-code-center-bottom{
                width: 680px;
                position: relative;
                margin-top: 12px;
                .invited-code-explain{
                    position: absolute;
                    margin-left: 127px;
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #333333;
                }
                .invited-code-explain-one{
                    position: absolute;
                    margin-left: 127px;
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    margin-top: 25px;
                }
                .invited-code-explain-tow{
                    position: absolute;
                    margin-left: 127px;   
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #999999; 
                    margin-top: 48px;
                }
            }
        }
    }
           
}
</style>