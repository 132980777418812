import JSZip from 'jszip'
import strings from "../../../../common/lang/strings"
import getString from "../../../../common/lang/language";
import MindType from "../../../../viewmodel/datatype/MindType"
import MindDisplayType from "../../../../viewmodel/datatype/MindDisplayType"
import NodeLayoutType from "../../../../viewmodel/datatype/NodeLayoutType"
import LineLayout from "../../../../viewmodel/datatype/LineLayout"
import MindElementType from "../../../../viewmodel/datatype/MindElementType"
import MindElementShapeType from "../../../../viewmodel/datatype/MindElementShapeType"
import LineMindTypeNodeUnit from "../../../../viewmodel/mindelementdata/LineMindTypeNodeUnit"
import MindElementCalculation from "../../calcule/elementCalculation/MindElementCalculation"
import MindDateFormat from "../MindDateFormat"
import Colors from "../../../../utils/Colors"
import LineMindTypeNode from "../../../../viewmodel/mindelementdata/LineMindTypeNode"
import MindBGStripesType from "../../../../viewmodel/datatype/MindBGStripesType"
if (
    typeof navigator === "object" &&
    typeof navigator.userAgent === "string" &&
    navigator.userAgent.indexOf("Electron") >= 0
) {
    var { ipcRenderer } = window.require("electron");

}
import {
    postMindmapNew,
} from "../../../../common/netWork/mind_map_api";
import Config from "../../calcule/Config"
import Strings from "../../../../utils/Strings"
import MindElementData from '../../../../viewmodel/mindelementdata/MindElementData'
import GeneralizationContent from '../../../../viewmodel/mindelementdata/mindcontent/GeneralizationContent'
import LineElementContent from '../../../../viewmodel/mindelementdata/mindcontent/LineElementContent'
import TextContent from '../../../../viewmodel/mindelementdata/mindcontent/TextContent'
import UiUtil from '../../../../utils/UiUtil'
import EncircleShapeType from '../../../../viewmodel/datatype/EncircleShapeType';
import RemarksElementContent from '../../../../viewmodel/mindelementdata/mindcontent/RemarksElementContent';
import LinkElementContent from '../../../../viewmodel/mindelementdata/mindcontent/LinkElementContent';
import HashMap from '../../../../viewmodel/core/base/HashMap';
import IdGenerator from '../../../../viewmodel/core/base/IdGenerator';
import ConnectLineType from '../../../../viewmodel/datatype/ConnectLineType';
import LinePointShape from '../../../../viewmodel/datatype/LinePointShape';
import SettingData from '../../../../viewmodel/core/minddata/SettingData';

class XMindFileImport {
    constructor(delegate, path) { //path 为.xmind路径

        this.delegate = delegate;
        this.path = path;
        this.xmindContentJson = "";
        this.rootDataStyle = new MindElementData()
        this.subDataStyle = new MindElementData()
        this.sonSubDataStyle = new MindElementData()
        this.generalizationDataStyle = new MindElementData()
        this.encircleDataStyle = new MindElementData()
        this.connectDataStyle = new MindElementData()
        this.freeDataStyle = new MindElementData()

        this.nodeMap = new HashMap();
        this.nodeConnectLineDataDict = new HashMap()

        this.title = "";
        this.dataList = new Array();
        this.mainMindNodeUnit;
        this.mindType = MindType.LINE_MAP;
        this.mindDisplayType = MindDisplayType.MindMap;
        this.layout = NodeLayoutType.LAYOUT_RIGHT;
        this.lineLayout = LineLayout.CURVE_LINE;
        this.mindElementShape = MindElementShapeType.Semicircle;
        this.mindSubElementShape = MindElementShapeType.CornerShadow;
        this.name = "";
        this.subtitle = "";
        this.baseLevel = 0;
        this.mindBGColor = Colors.white;
        this.mianLineWidth = 2;
        this.mianLineColor = 0x222222;
        this.mainLineColors = []
        this.mainMindNodeUnit = null
        this.freeNodes = new Array();
        this.nodeIdsMap = new HashMap()
        this.initData();
    }

    initData() {
        IdGenerator.shared.resetId()
        this.mainMindNodeUnit = new LineMindTypeNodeUnit(this.mindBGColor, MindType.LINE_MAP, 2, false);
        this.mainMindNodeUnit.setMindBGColor(this.mainMindNodeUnit.mindMapStyleColor.getMindMapFillColor());
        this.mainMindNodeUnit.setMindDisplayType(this.mindDisplayType);
        this.mainMindNodeUnit.createRootNode();
        this.mainMindNodeUnit.setElementLayout(this.layout, true);
        this.mainMindNodeUnit.globalLineLayout = this.lineLayout;

        this.rootDataStyle.id = 100000
        this.subDataStyle.id = 100000
        this.sonSubDataStyle.id = 100000
        this.generalizationDataStyle.id = 100000
        this.encircleDataStyle.id = 100000
        this.connectDataStyle.id = 100000
        this.freeDataStyle.id = 100000
        this.mainLineColors = []

        this.rootDataStyle.textContent = new TextContent("")
        this.subDataStyle.textContent = new TextContent("")
        this.sonSubDataStyle.textContent = new TextContent("")
        this.generalizationDataStyle.generalizationContent = new GeneralizationContent("")
        this.encircleDataStyle.lineContent = new LineElementContent()
        this.encircleDataStyle.lineContent.textContent = new TextContent("")
        this.encircleDataStyle.lineContent.encircleShapeType = EncircleShapeType.LAYOUT_RECTANGLE_2
        this.connectDataStyle.lineContent = new LineElementContent()
        this.connectDataStyle.lineContent.textContent = new TextContent("")
        this.freeDataStyle.textContent = new TextContent("")

        this.unzip();
    }

    format() {
        //this.xmindContentJson 转对象
        if (new Strings().isEmpty(this.xmindContentJson)) {
            // 提示内容为空
            this.delegate.$message.error(getString(strings.Mind_Group_The_Content_Cannot_Be_Empty));
            return;
        }
        this.xmindContentJson = JSON.parse(this.xmindContentJson);
        if (this.xmindContentJson.length == 0) {
            // 提示内容为空
            this.delegate.$message.error(getString(strings.Mind_Group_The_Content_Cannot_Be_Empty));
            return;
        }
        this.parseTheme(this.xmindContentJson[0])
        let nodeCount = this.dataList.length;
        if (nodeCount < 0 || this.mainMindNodeUnit.rootTreeNode == null) {
            return;
        }
        //console.log(this.xmindContentJson[0].rootTopic);
        if (this.xmindContentJson[0] != null && this.xmindContentJson[0].rootTopic != null) {
            this.parseNodes(this.xmindContentJson[0].rootTopic)
        }

        if (this.xmindContentJson[0] != null && this.xmindContentJson[0].relationships != null) {
            this.parseConntentLine(this.xmindContentJson[0].relationships)
        }


        let lines = this.mainMindNodeUnit.lineMindElementDataDict.values()
        let lineConut = lines.length
        for (let index = 0; index < lineConut; index++) {
            let cell = lines[index]
            if (cell.lineContent != null) {
                cell.lineContent.color = this.mianLineColor
                cell.lineContent.lineWidth = this.mianLineWidth
            }
        }
        this.save();
    }

    save() {
        let mindDateFormat = new MindDateFormat();
        mindDateFormat.mindBGColor = this.mindBGColor;
        mindDateFormat.mindDisplayType = this.mindDisplayType;
        mindDateFormat.styleIndex = this.mainMindNodeUnit.mindMapStyleColor.currentSelectedIndex;
        mindDateFormat.version = Config.MindFormatVersion;
        mindDateFormat.mindGlobalAudio = "";
        mindDateFormat.mindBGStripesType = MindBGStripesType.NonExistent;
        this.name = this.mainMindNodeUnit.rootTreeNode.value.textContent.text
        if (this.mainLineColors.length > 0) {
            this.mainLineColors.splice(0, 0, this.rootDataStyle.backgroundColor); 
            mindDateFormat.settingData.lineColor = this.mainLineColors
            this.mainMindNodeUnit.onSelectedRainbowr(this.mainLineColors, true)
        }
        
        let freeUnitNodes = new Array();
        let length = this.freeNodes.length;
        for (let index = 0; index < length; index++) {
            const unit = this.freeNodes[index];
            if (unit.rootTreeNode == null) {
                return;
            }
            let unitMindDateFormat = new MindDateFormat();
            let lineMindElementDataList = unit.lineMindElementDataDict.values();
            let generalizationLineMindElementDataList = unit.generalizationLineMindElementDataDict.values();
            let generalizationNode = unit.generalizationMindElementDataDict.values();
            let encircle = unit.encircleMindElementDataDict.values();

            unitMindDateFormat = unitMindDateFormat.setDatas(unit.rootTreeNode, lineMindElementDataList,
                generalizationLineMindElementDataList,
                generalizationNode,
                new Array(),
                encircle)
                .setLayout(unit.globalLayout, unit.globalLineLayout);
            freeUnitNodes.push(unitMindDateFormat);
        }
        mindDateFormat.nodeConnectLinet = this.nodeConnectLineDataDict.values();
        mindDateFormat = mindDateFormat.setDatas(
            this.mainMindNodeUnit.rootTreeNode,
            this.mainMindNodeUnit.lineMindElementDataDict.values(),
            this.mainMindNodeUnit.generalizationLineMindElementDataDict.values(),
            this.mainMindNodeUnit.generalizationMindElementDataDict.values(),
            freeUnitNodes,
            this.mainMindNodeUnit.encircleMindElementDataDict.values())
            .setLayout(this.layout, this.lineLayout);
        let mindNodeJson = mindDateFormat.toJSONString();
        let mindTitle = new Strings().isEmpty(this.name) ? "MindMap" : this.name;
        IdGenerator.shared.resetId()
        if (mindNodeJson != null) { //创建导图到服务器，content= mindNodeJson, ...根据服务器返回，跳转进入导图
            const obj = {
                parentEntityId: '',
                name: mindTitle,
                content: mindNodeJson,
                type: this.mindType,
                cover: '',
                subtitle: '',
            }
            postMindmapNew(
                obj,
                (res) => {
                    // this.delegate.$message.success(getString(strings.Message_Tips_Create_Success));
                    let mindId = res.id;
                    if (this.delegate.$tools.isElectron()) {
                        ipcRenderer.send("openCalendarWindow", "/mindmap?id=" + mindId);
                    } else {
                        const routerData = this.delegate.$router.resolve({
                            path: "/mindmap",
                            query: {
                                id: mindId,
                            },
                        });
                        this.createATagJump(routerData.href);
                    }
                },
                (error) => {
                    this.delegate.$message.error(error.desc);
                }
            );
            this.delegate.reload();
            this.delegate.showModal = false;//隐藏导入文件弹窗

        }
    }

    createATagJump(url) {
        const a = document.createElement('a');//创建a标签浏览器不会阻止弹出窗口
        a.setAttribute('href', url);
        a.setAttribute('target', '_blank');
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
            document.body.removeChild(a);
        }, 5000);
    }

    parseConntentLine(relationships) {
        if (relationships == null || relationships.length <= 0) {
            return
        }
        let conut = relationships.length
        for (let index = 0; index < conut; index++) {
            let cell = relationships[index]
            if (!cell.end1Id || !cell.end2Id ||
                !this.nodeMap.containsKey(cell.end1Id) ||
                !this.nodeMap.containsKey(cell.end2Id)) {
                continue
            }
            let targteNode = this.nodeMap.get(cell.end1Id);
            let toNode = this.nodeMap.get(cell.end2Id);

            let line = new MindElementData();
            line.id = IdGenerator.shared.getId();
            line.parentNodeId = targteNode.value.id;
            line.type = MindElementType.NODE_CONNECT_LINE;
            line.backgroundColor = Colors.clear;
            line.borderColor = Colors.clear;
            line.borderWidth = 0;
            let lineContent = new LineElementContent();
            lineContent.targetId = toNode.value.id;
            lineContent.connectLineType = ConnectLineType.CURVE_LINE
            lineContent.startShape = LinePointShape.NORMAL;
            lineContent.endShape = LinePointShape.ARROW;
            if (cell.title) {
                lineContent.textContent = new TextContent(cell.title)
            }
            if (this.addNodeConnectLineType == ConnectLineType.STRAIGHT_CIRCULAR_LINE ||
                this.addNodeConnectLineType == ConnectLineType.RIGHT_ANGLE_LINE) {
                lineContent.nodeConnectLineDottedLine = false;
            }
            line.lineContent = lineContent;
            line.stickStyle(this.connectDataStyle)
            if (cell.style && cell.style.properties) {
                this.parseNodeProperties(cell.style.properties, line, false, true)
            }
            line.backgroundColor = Colors.clear;
            line.borderColor = Colors.clear;
            line.borderWidth = 0;
            if (cell.controlPoints &&
                cell.controlPoints["0"] &&
                cell.controlPoints["1"]) {

            }
            this.nodeConnectLineDataDict.put(line.id, line);
        }
    }

    parseFreeNode(detached) {
        if (detached == null || detached.length <= 0) {
            return
        }
        let rootX = this.mainMindNodeUnit.rootTreeNode.value.x
        let rootY = this.mainMindNodeUnit.rootTreeNode.value.y
        let rootRect = this.mainMindNodeUnit.getRect()
        let conut = detached.length
        for (let index = 0; index < conut; index++) {
            let cell = detached[index]

            let mindNodeUnit = new LineMindTypeNodeUnit(this.mindBGColor, this.mindType, this.mainMindNodeUnit.mindMapStyleColor.currentSelectedIndex, true);
            mindNodeUnit.setElementLayout(NodeLayoutType.LAYOUT_RIGHT, true);
            mindNodeUnit.globalLineLayout = LineLayout.RIGHT_ANGLE_CORNER_LINE;
            mindNodeUnit.setMindBGColor(this.mindBGColor);
            this.freeNodes.push(mindNodeUnit);

            let node = new LineMindTypeNode();
            node = mindNodeUnit.createFreeNode(-1, 1);
            mindNodeUnit.setRootNode(node);
            this.parseNode(cell, node, mindNodeUnit)

            if (cell.position) {
                node.value.x = rootX + cell.position.x
                node.value.y = rootY + cell.position.y
            } else {
                node.value.x = rootRect.x + rootRect.width() + 100
                node.value.y = rootRect.y + rootRect.height() + 100 * (index + 1)
            }
            mindNodeUnit.refreshLayout(true, new SettingData());
        }
    }

    parseNode(json, node, unit) {
        if (json == null || node == null || node.isEmpty()) {
            return;
        }
        if (json.id) {
            this.nodeMap.put(json.id, node);
        }
        //console.log("--", json.title, node.value);
        if (json.title) {
            if (node.value.generalizationContent) {
                node.value.generalizationContent.text = json.title
            } else if (node.value.textContent) {
                node.value.textContent.text = json.title
            }
            if (node.value.type == MindElementType.MAIN_SUBJECT) {
                node.stickStyle(this.rootDataStyle)
            } else if (node.value.type == MindElementType.SUBJECT ) {
                node.stickStyle(this.subDataStyle)
            } else if (node.value.type == MindElementType.SON_SUBJECT) {
                node.stickStyle(this.sonSubDataStyle)
            } else if (node.value.type == MindElementType.CONTENT_GENERALIZATION) {
                node.stickStyle(this.generalizationDataStyle)
            }
        }
        if (json.labels && json.labels.length > 0) {
            node.value.explain = json.labels[0]
        }
        if (json.notes && json.notes.plain && json.notes.plain.content) {
            node.value.remarksElementContent = new RemarksElementContent(json.notes.plain.content, 20, 20);
            node.value.remarksElementContent.text = json.notes.plain.content
        }
        if (json.href) {
            let list = new Array();
            list.push(json.href);
            node.value.linkElementContent = new LinkElementContent(list, 20, 20);
        }

        if (json.structureClass) {
            let layout = this.getNodeLayout(json.structureClass)

            if (node.value.id == unit.rootTreeNode.value.id) {
                if (unit == this.mainMindNodeUnit) {
                    this.layout = layout
                    if (layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT) {
                        this.lineLayout = LineLayout.RIGHT_ANGLE_CORNER_LINE
                    }
                }
                unit.setElementLayout(layout, false)
                if (layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT) {
                    unit.globalLineLayout = LineLayout.RIGHT_ANGLE_CORNER_LINE
                }
                node.value.layout = layout
            } else {
                unit.setNodeLayout(node.value.id, layout)
            }
        }

        if (json.style && json.style.properties) {
            this.parseNodeProperties(json.style.properties, node.value, false)
        }
        if (node.value.borderWidth > 0 && Colors.isClear(node.value.borderColor)) {
            node.value.borderColor = this.mianLineColor
        }
        MindElementCalculation.set(node.value).caluleTextForData();
        if (json.children && json.children.attached && json.children.attached.length > 0) {
            let childCount = json.children.attached.length
            for (let index = 0; index < childCount; index++) {
                let newNode = unit.addChildMindElement(node.value.id);
                this.parseNode(json.children.attached[index], newNode, unit)

            }
        }
        
        if (json.children && json.children.summary && json.children.summary.length > 0) {
            let childCount = json.children.summary.length
            for (let index = 0; index < childCount; index++) {
                let newNode = unit.addGeneralizationContent(node.value.id);
                this.parseNode(json.children.summary[index], newNode, unit)
                let hitTarget = this.parseGeneralizationTarget(node, newNode, json.summaries)
                if (!hitTarget) {
                    unit.removeNodeGeneralization(node)
                }
            }
        }
        if (json.boundaries && json.boundaries.length > 0) {
            let boundariesCount = json.boundaries.length
            for (let index = 0; index < boundariesCount; index++) {
                let cell = json.boundaries[index]
                if (!cell.range) {
                    continue
                }

                let range = this.parseRange(cell.range)
                if (range.isEmpty() || range.length != 2 ||
                    range[0] >= node.children.length ||
                    range[1] >= node.children.length ||
                    range[0] > range[1]) {
                    continue
                }
                let encircleData = unit.setEncircleNodes(node.children[range[0]].value.id)
                if (encircleData.isEmpty() || encircleData.lineContent == null) {
                    continue
                }
                if (cell.title) {
                    encircleData.lineContent.textContent = new TextContent(cell.title)
                }
                encircleData.stickStyle(this.encircleDataStyle)
                if (range[1] > range[0]) {
                    let childCount = node.children.length
                    for (let index = 0; index < childCount; index++) {
                        if (index > range[0] && index <= range[1]) {
                            encircleData.lineContent.targetIds.push(node.children[index].value.id)
                        }
                    }
                }
            }
        }
    }

    parseGeneralizationTarget(parentNode, generalizationNode, summaries) {
        if (parentNode == null || parentNode.isEmpty() || 
            generalizationNode == null || generalizationNode.isEmpty() ||
            summaries == null || summaries.length == 0) {
            return false;
        }

        let count = summaries.length
        for (let i = 0; i < count; i++) {
            let cell = summaries[i]
            if (cell.topicId == null || cell.range == null) {
                continue;
            }

            let topicId = cell.topicId
            let range = this.parseRange(cell.range)
            if (range.isEmpty() || range.length != 2 ||
                    range[0] >= parentNode.children.length ||
                    range[1] > parentNode.children.length ||
                    range[0] > range[1]) {
                    continue
            }
            if (!this.nodeMap.containsKey(topicId) || generalizationNode.value.id != this.nodeMap.get(topicId).value.id) {
                continue
            }
            let targetIds = []
            generalizationNode.value.generalizationContent.targetId = parentNode.children[range[0]].value.id
            for (let j = range[0]; j < range[1] + 1; j++) {
                targetIds.push(parentNode.children[j].value.id)
            }
            generalizationNode.value.generalizationContent.targetIds = targetIds
            return true;
        }
        return false;
    }
    
    parseNodes(xmindRootTopic) {
        if (xmindRootTopic == null) {
            return;
        }
        let mainMindNodeUnit = this.mainMindNodeUnit;
        if (mainMindNodeUnit.rootTreeNode == null) {
            return;
        }
        this.parseNode(xmindRootTopic, mainMindNodeUnit.rootTreeNode, mainMindNodeUnit)
        this.mainMindNodeUnit.refreshLayout(false, new SettingData());

        if (xmindRootTopic.children && xmindRootTopic.children.detached) {
            this.parseFreeNode(xmindRootTopic.children.detached)
        }
    }

    getNodeLayout(structureClass) {
        if (structureClass == null) {
            return this.layout
        }
        switch (structureClass) {
            case "org.xmind.ui.logic.right":
                return NodeLayoutType.LAYOUT_RIGHT
            case "org.xmind.ui.tree.left":
                return NodeLayoutType.LAYOUT_TREE_LEFT
            case "org.xmind.ui.tree.rigtht":
                return NodeLayoutType.LAYOUT_TREE_RIGHT
            case "org.xmind.ui.logic.left":
                return NodeLayoutType.LAYOUT_LEFT
            case "org.xmind.ui.map.unbalanced":
                return NodeLayoutType.LAYOUT_RIGHT_LEFT
            case "org.xmind.ui.map.clockwise":
                return NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE
            case "org.xmind.ui.org-chart.down":
                return NodeLayoutType.LAYOUT_BOTTOM
            case "org.xmind.ui.fishbone.leftHeaded":
                return NodeLayoutType.LAYOUT_FISH_RIGHT
            case "org.xmind.ui.spreadsheet":
                return NodeLayoutType.LAYOUT_FORM
            case "org.xmind.ui.brace.right":
                return NodeLayoutType.LAYOUT_BRACKETS_RIGHT

        }
        return this.layout
    }

    unzip() {//解压.xmind, 获取content.js 内容给 this.xmindContentJson
        const jszip = new JSZip();
        jszip.loadAsync(this.path).then((zip) => { // 读取zip
            let contentJson = "content.json";
            let contentXml = "content.xml";
            if (zip.files[contentJson]) {//判断是否有content.json文件
                let base = zip.file(contentJson).async('string'); // 以字符串形式输出文本内容;
                base.then(res => {
                    this.xmindContentJson = res;
                    this.format();
                })
            } else {
                this.delegate.showModal = false;//隐藏导入文件弹窗
                this.delegate.$message.error(getString(strings.Not_Supported_At_The_Moment_This_Type_Xmind_File));
                this.delegate.reload();
            }
        });
    }

    parseTheme(json) {
        if (json == null || json == undefined || json.theme == null) {
            return;
        }
        let theme = json.theme;
        if (theme.map != null && theme.map.properties != null) {
            this.mindBGColor = Colors.getColorIntValue(theme.map.properties["svg:fill"])
            if (Colors.isDarkColor(this.mindBGColor)) {
                this.mainMindNodeUnit.setMindMapStyleColor(8);
            }

            if (theme.map.properties["multi-line-colors"] != null && 
                theme.map.properties["multi-line-colors"].length > 0) {
                let lineColors = theme.map.properties["multi-line-colors"]
                if (lineColors.indexOf(" ") > -1) {//"multi-line-colors": "#F9423A #F6A04D #F3D321 #00BC7B #486AFF #4D49BE",
                    let colors = lineColors.split(" ")
                    if (colors.length > 0) {
                        for (let i = 0; i < colors.length; i++) {
                            let cellColor = Colors.getColorIntValue(colors[i])
                            this.mainLineColors.push(cellColor)
                        }
                    }
                }
            }
            
            this.mainMindNodeUnit.setMindBGColor(this.mindBGColor);
        }

        if (theme.centralTopic != null && theme.centralTopic.properties != null) {
            this.parseNodeProperties(theme.centralTopic.properties, this.rootDataStyle)
            if (theme.centralTopic.properties["line-width"]) {
                this.mianLineWidth = new UiUtil().dip2px(theme.centralTopic.properties["line-width"]);
            }
            if (theme.centralTopic.properties["line-color"]) {
                this.mianLineColor = Colors.getColorIntValue(theme.centralTopic.properties["line-color"])
            }
            this.rootDataStyle.styleColor = this.mainMindNodeUnit.mindMapStyleColor.getLineColor();
            this.rootDataStyle.styleFillColor = this.mainMindNodeUnit.mindMapStyleColor.getSubjectViewFillColor();
        }
        if (theme.mainTopic != null && theme.mainTopic.properties != null) {
            this.parseNodeProperties(theme.mainTopic.properties, this.subDataStyle)
            this.subDataStyle.styleColor = this.mainMindNodeUnit.mindMapStyleColor.getLineColor();
            this.subDataStyle.styleFillColor = this.mainMindNodeUnit.mindMapStyleColor.getSubjectViewFillColor();
        }
        if (theme.subTopic != null && theme.subTopic.properties != null) {
            this.parseNodeProperties(theme.subTopic.properties, this.sonSubDataStyle)
            this.sonSubDataStyle.styleColor = this.mainMindNodeUnit.mindMapStyleColor.getLineColor();
            this.sonSubDataStyle.styleFillColor = this.mainMindNodeUnit.mindMapStyleColor.getSubjectViewFillColor();
        }
        if (theme.summaryTopic != null && theme.summaryTopic.properties != null) {
            this.parseNodeProperties(theme.summaryTopic.properties, this.generalizationDataStyle)
            this.generalizationDataStyle.styleColor = this.mainMindNodeUnit.mindMapStyleColor.getLineColor();
            this.generalizationDataStyle.styleFillColor = this.mainMindNodeUnit.mindMapStyleColor.getSubjectViewFillColor();
        }
        if (theme.floatingTopic != null && theme.floatingTopic.properties != null) {
            this.parseNodeProperties(theme.floatingTopic.properties, this.freeDataStyle)
            this.freeDataStyle.styleColor = this.mainMindNodeUnit.mindMapStyleColor.getLineColor();
            this.freeDataStyle.styleFillColor = this.mainMindNodeUnit.mindMapStyleColor.getSubjectViewFillColor();
        }
        if (theme.boundary != null && theme.boundary.properties != null) {
            this.parseNodeProperties(theme.boundary.properties, this.encircleDataStyle, true, true)
        }
        if (theme.relationship != null && theme.relationship.properties != null) {
            this.parseNodeProperties(theme.relationship.properties, this.connectDataStyle, true, true)
        }
    }

    parseRange(range) {
        if (range == null || !range.startsWith("(") || !range.endsWith(")") || range.indexOf(",") <= 0) {
            return []
        }
        let arr = []
        range = range.replace("(", "")
        range = range.replace(")", "")
        let arrStr = range.split(",")
        for (let index = 0; index < arrStr.length; index++) {
            arr.push(parseInt(arrStr[index]))
        }
        return arr
    }

    parseNodeProperties(properties, mindData, isSetEmpty = true, isLine = false) {
        if (properties == null || mindData == null) {
            return;
        }
        if (isLine) {
            mindData.backgroundColor = Colors.clear
            mindData.borderColor = Colors.clear
            mindData.borderWidth = 0
            mindData.mindElementShape = MindElementShapeType.Corner
        } else {
            if (properties["svg:fill"]) {
                mindData.backgroundColor = Colors.getColorIntValue(properties["svg:fill"])
            } else {
                mindData.backgroundColor = this.mindBGColor
            }

            if (properties["border-line-color"] == null) {
                if (isSetEmpty) {
                    mindData.borderColor = Colors.clear
                }
            } else {
                mindData.borderColor = Colors.getColorIntValue(properties["border-line-color"])
            }
            if (properties["border-line-width"] == null) {
                if (properties["border-line-color"] != null) {
                    mindData.borderWidth = 2
                } else if (isSetEmpty) {
                    mindData.borderWidth = 0
                }
            } else {
                mindData.borderWidth = new UiUtil().dip2px(properties["border-line-width"])
            }
            if (properties["shape-class"]) {
                mindData.mindElementShape = this.transformElementShape(properties["shape-class"])
            }
        }

        if (mindData.textContent != null) {
            if (isSetEmpty || properties["fo:color"]) {
                if (properties["fo:color"]) {
                    mindData.textContent.textColor = Colors.getColorIntValue(properties["fo:color"])
                    if (Colors.isClear(mindData.backgroundColor)) {
                        if (Colors.isDarkColor(this.mindBGColor)) {
                            mindData.textContent.textColor = Colors.white
                        } else {
                            mindData.textContent.textColor = Colors.black80
                        } 
                    }
                } else {
                    if (Colors.isClear(mindData.backgroundColor)) {
                        if (Colors.isDarkColor(this.mindBGColor)) {
                            mindData.textContent.textColor = Colors.white
                        } else {
                            mindData.textContent.textColor = Colors.black80
                        } 
                    } else if (Colors.isDarkColor(mindData.backgroundColor)) {
                        mindData.textContent.textColor = Colors.white
                    } else {
                        mindData.textContent.textColor = Colors.black80
                    }
                }
            } else {
                if (Colors.isClear(mindData.backgroundColor)) {
                    if (Colors.isDarkColor(this.mindBGColor)) {
                        mindData.textContent.textColor = Colors.white
                    } else {
                        mindData.textContent.textColor = Colors.black80
                    } 
                } else if (Colors.isDarkColor(mindData.backgroundColor)) {
                    mindData.textContent.textColor = Colors.white
                } else {
                    mindData.textContent.textColor = Colors.black80
                }
            }

            if (properties["fo:font-size"] || isSetEmpty) {
                if (properties["fo:font-size"]) {
                    mindData.textContent.textFontSize = new UiUtil().dip2px(properties["fo:font-size"])
                } else {
                    mindData.textContent.textFontSize = 18
                }
            }
            if (properties["fo:font-weight"] || isSetEmpty) {
                if (properties["fo:font-weight"]) {
                    mindData.textContent.textBold = new UiUtil().dip2px(properties["fo:font-weight"]) >= 500
                } else {
                    mindData.textContent.textBold = false
                }
            }
        }
        if (mindData.generalizationContent != null) {
            if (properties["fo:color"] || isSetEmpty) {
                if (properties["fo:color"]) {
                    mindData.generalizationContent.textColor = Colors.getColorIntValue(properties["fo:color"])
                    if (Colors.isClear(mindData.backgroundColor)) {
                        if (Colors.isDarkColor(this.mindBGColor)) {
                            mindData.generalizationContent.textColor = Colors.white
                        } else {
                            mindData.generalizationContent.textColor = Colors.black80
                        } 
                    }
                } else {
                    if (Colors.isClear(mindData.backgroundColor)) {
                        if (Colors.isDarkColor(this.mindBGColor)) {
                            mindData.generalizationContent.textColor = Colors.white
                        } else {
                            mindData.generalizationContent.textColor = Colors.black80
                        } 
                    } else if (Colors.isDarkColor(mindData.backgroundColor)) {
                        mindData.generalizationContent.textColor = Colors.white
                    } else {
                        mindData.generalizationContent.textColor = Colors.black80
                    }
                }
            } else {
                if (Colors.isClear(mindData.backgroundColor)) {
                    if (Colors.isDarkColor(this.mindBGColor)) {
                        mindData.generalizationContent.textColor = Colors.white
                    } else {
                        mindData.generalizationContent.textColor = Colors.black80
                    } 
                } else if (Colors.isDarkColor(mindData.backgroundColor)) {
                    mindData.generalizationContent.textColor = Colors.white
                } else {
                    mindData.generalizationContent.textColor = Colors.black80
                }
            }
            if (properties["fo:font-size"] || isSetEmpty) {
                mindData.generalizationContent.textFontSize = new UiUtil().dip2px(properties["fo:font-size"])
            }
            if (properties["fo:font-weight"] || isSetEmpty) {
                mindData.generalizationContent.textBold = new UiUtil().dip2px(properties["fo:font-weight"]) >= 500
            }
        }
        if (mindData.lineContent != null) {
            if (properties["svg:fill"] || isSetEmpty) {
                mindData.lineContent.color = properties["svg:fill"] ? Colors.getColorIntValue(properties["svg:fill"]) : Colors.clear
            }
            if (properties["line-pattern"] || isSetEmpty) {
                if (properties["line-pattern"]) {
                    if (properties["line-pattern"] == "solid") {
                        mindData.lineContent.dottedLine = false
                        mindData.lineContent.nodeConnectLineDottedLine = false
                    } else if (properties["line-pattern"] == "dash") {
                        mindData.lineContent.dottedLine = true
                        mindData.lineContent.nodeConnectLineDottedLine = true
                    }
                } else {
                    mindData.lineContent.dottedLine = true
                    mindData.lineContent.nodeConnectLineDottedLine = true
                }
            }

            if (properties["line-color"] || isSetEmpty) {
                mindData.lineContent.strokeColor = properties["line-color"] ? Colors.getColorIntValue(properties["line-color"]) : Colors.clear
                if (properties["svg:fill"] == null && properties["line-color"]) {
                    mindData.lineContent.color = Colors.getColorIntValue(properties["line-color"])
                }
            }
            if (properties["line-width"] || isSetEmpty) {
                mindData.lineContent.lineWidth = properties["line-width"] ? new UiUtil().dip2px(properties["line-width"]) : 2
            }
            if (mindData.lineContent.textContent != null) {
                if (properties["fo:color"] || isSetEmpty) {
                    mindData.lineContent.textContent.textColor = Colors.getColorIntValue(properties["fo:color"])
                }
                if (properties["fo:font-size"] || isSetEmpty) {
                    mindData.lineContent.textContent.textFontSize = new UiUtil().dip2px(properties["fo:font-size"])
                }
                if (properties["fo:font-weight"] || isSetEmpty) {
                    mindData.lineContent.textContent.textBold = new UiUtil().dip2px(properties["fo:font-weight"]) >= 500
                }
            }
        }
    }

    transformElementShape(xmind) {
        if (xmind == null) {
            return MindElementShapeType.Corner
        }
        switch (xmind) {
            case "org.xmind.topicShape.roundedRect":
            case "org.xmind.topicShape.rect":
                return MindElementShapeType.Corner
            case "org.xmind.topicShape.ellipserect":
                return MindElementShapeType.Semicircle
            case "org.xmind.topicShape.ellipse":
                return MindElementShapeType.Ellipse
            case "org.xmind.topicShape.underline":
                return MindElementShapeType.Underline
            case "org.xmind.topicShape.diamond":
                return MindElementShapeType.Diamond
            case "org.xmind.topicShape.circle":
                return MindElementShapeType.Circular
            case "org.xmind.topicShape.hexagon":
                return MindElementShapeType.Hexagon
            case "org.xmind.topicShape.singlebreakangle":
                return MindElementShapeType.Corner_Right
            case "org.xmind.topicShape.roundedhexagon":
            case "org.xmind.topicShape.ellipticrectangle":
                return MindElementShapeType.Left_Right_Corner_Top_Bottom_Semicircle

        }
        return MindElementShapeType.Corner
    }
}

export default XMindFileImport