<template>
  <div
    v-show="showAboutProjectModal"
    @click="showAboutModal(false)"
    class="version-modal"
  >
    <div @click.stop="showAboutModal(true)" class="about-modal-center">
      <div class="about-header">
        <img src="../../assets/img/version/version.png" alt="新版本上线" />
        <!-- 新版本上线 -->
        <h2 class="new-version-online">
          {{ getString(strings.New_Version_Online) }}
        </h2>
        <div class="close-btn">
          <img
            class="close-button-img"
            src="../../assets/img/version/colse.png"
            @click.stop="showAboutModal(false, false)"
          />
        </div>
      </div>
      <div class="about-content">
        <div class="about-content-text about-content-title">
          {{ this.showData.newVersion }}
        </div>
        <div
          v-if="this.showData != null"
          class="about-content-text about-content-body"
        >
          <pre>{{ showData.desc }}</pre>
        </div>
      </div>
      <div v-if="schadule == null" class="about-footer">
        <!-- 稍后安装 -->
        <div @click.stop="showAboutModal(false, false)" class="footer-button">
          {{ getString(strings.install_Later) }}
        </div>
        <!-- 立即升级 -->
        <div
          @click.stop="showAboutModal(false, true)"
          class="footer-button now-version-button-red"
        >
          {{ getString(strings.Upgrade_Now) }}
        </div>
      </div>
      <div v-else class="now-down-fill">
        <a-progress :percent="schadule" :strokeWidth="20" status="active" />
      </div>
      <div class="link-box">
        <a ref="downfile" target="_self" :href="downUrl"></a>
      </div>
    </div>
    <!-- 预加载 -->
    <img
      src="../../assets/img/version/version.png"
      style="display: none"
      @load="loadImage"
    />
  </div>
</template>

<script>
if (
  typeof navigator === "object" &&
  typeof navigator.userAgent === "string" &&
  navigator.userAgent.indexOf("Electron") >= 0
) {
  var { ipcRenderer, ipcMain } = window.require("electron");
  // var ipcRenderer = window.ipcRenderer
}

import { mapMutations } from "vuex";
import getString from "../../common/lang/language";
import strings from "../../common/lang/strings";
export default {
  data() {
    return {
      strings,
      pac: "",
      showAboutProject: true,
      showAboutProjectModal: false,
      showData: null,
      schadule: null,
      isElectron: false,
      downUrl: "",
    };
  },
  created() {
    this.pac = require("../../../package.json");
    this.isElectron = this.$tools.isElectron();
    if (this.isElectron) {
      // 设置当前的下载进度
      ipcRenderer.on("test", (event, data) => {
        //   console.log('数字', data)
      });
      // 设置当前的下载进度
      ipcRenderer.on("getDownLoadSchedule", (event, data) => {
        // console.log('newval改变了', data);
        this.schadule = parseInt(data.percent * 100);
        // shell.openExternal(url);
        //   console.log(data);
      });
    }
  },
  methods: {
    ...mapMutations(["setVersionUpdatingModal"]),
    getString(i) {
      return getString(i);
    },
    showAboutModal(show, update) {
      if (show == true) {
        this.showAboutProject = true;
      } else {
        if (update) {
          // if (this.showData.isDirectDownloadLink) {   //有直接链
          //     this.$refs.downfile.click()
          // } else {    //无直链
          //     this.jumpOfficialWebsite(this.showData.url);
          // }
          this.jumpOfficialWebsite(this.showData.url);
        }
        this.showAboutProject = false;
        //关闭页面的时候顺便清空版本数据，这样下次点击或者请求还会展示弹框。
        this.setVersionUpdatingModal(null);
      }
    },
    jumpOfficialWebsite(val) {
      if (arguments.length == 1) {
        ipcRenderer.send("open-url", val);
      } else {
        ipcRenderer.send("open-url", "https://www.mindyushu.com/");
      }
    },
    loadImage() {
      this.showAboutProjectModal = this.showAboutProject;
    },
  },
  watch: {
    "$store.state.newVersionMessage": {
      handler(newval, oldval) {
        if (newval == null) {
          this.showAboutProject = false;
        } else {
          this.showAboutProject = true;
          this.showData = newval;
        }
      },
      immediate: true, //在一开是绑定的时候执行
      deep: true, //深度监听
    },
    showAboutProject(newVal) {
      this.showAboutProjectModal = newVal;
    },
  },
};
</script>

<style lang="less" scoped>
.version-modal {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  .about-modal-center {
    position: absolute;
    width: 537px;
    min-height: 200px;
    border-radius: 10px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    .about-header {
      display: flex;
      background: #f6f6f6;
      justify-content: space-between;
      align-items: center;
      // padding-top: 20px;
      // padding-left: 20px;
      // padding-right: 20px;
      border-radius: 10px 10px 0 0;
      .about-header-left {
        display: flex;
        .about-header-icon {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
      }
      .close-about {
        cursor: pointer;
      }
      .new-version-online {
        font-size: 36px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        text-shadow: 0px 3px 10px rgba(255, 76, 32, 0.5);
        position: absolute;
        line-height: 50px;
        top: 60px;
        left: 30px;
      }
      .close-btn {
        position: absolute;
        right: 15px;
        top: 8px;
      }

      .close-button-img {
        width: 14px;
        cursor: pointer;
      }
      .close-button-img:hover {
        opacity: 0.8;
      }
    }
    .about-content {
      background: #fff;
      min-height: 160px;
      position: relative;
      top: -10px;
      .about-content-text {
        font-size: 14px;
        color: #333;
        line-height: 24px;
        padding-left: 36px;
        padding-right: 36px;
      }
      .about-content-title {
        text-align: center;
        font-size: 20px;
        color: #000;
        font-weight: 700;
      }
      .about-content-body {
        margin-top: 20px;
        min-height: 100px;
        max-height: 144px;
        overflow-y: auto;
        // text-indent: 2em;
        pre {
          margin-top: 0;
          margin-bottom: 0;
          overflow: auto;
          &::-webkit-scrollbar {
            height: 8px;
            width: 5px;
            scrollbar-arrow-color: rgba(0, 0, 0, 0.1);
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background: rgba(0, 0, 0, 0.1);
          }
          &::-webkit-scrollbar-track {
            --webkit-box-shadow: inset 0 0 5px #ffffff;
            border-radius: 4px;
            background: #ffffff;
          }
        }
        &::-webkit-scrollbar {
          height: 8px;
          width: 5px;
          scrollbar-arrow-color: rgba(0, 0, 0, 0.1);
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background: rgba(0, 0, 0, 0.1);
        }
        &::-webkit-scrollbar-track {
          --webkit-box-shadow: inset 0 0 5px #ffffff;
          border-radius: 4px;
          background: #ffffff;
        }
      }
    }
    .about-footer {
      // height: 106px;
      text-align: center;
      color: #666;
      font-size: 12px;
      padding-top: 20px;
      padding-bottom: 20px;
      display: flex;
      justify-content: center;
      .footer-button {
        width: 112px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        font-size: 14px;
        color: #999;
        border: 1px solid #eeeeee;
        background-color: #fff;
        border-radius: 4px;
        margin: 26px;
        margin-bottom: 0;
        margin-top: 0;
        transition: all 0.5s;
        position: relative;
        cursor: pointer;
        // &:hover {
        //     background-color:#FF2400;
        //     color: #fff;
        // }
        &:active {
          left: 0;
          top: 1px;
        }
      }
      .now-version-button-red {
        background-color: #ff6039;
        color: #fff;
        font-weight: 700;
      }
    }
    .now-down-fill {
      width: 500px;
      margin: 0 auto;
      padding: 30px 0;
    }
    .link-box {
      position: absolute;
      z-index: -1;
      visibility: hidden;
    }
  }
}
</style>
