<template>
  <div class="group-scroll-x-box menu-silder">
    <div
      class="mind-class-content clearfix"
      
    >
      <div
        v-for="(each, index) in data"
        :key="index"
        @click="clickCreated(each, index)"
        class="modal-box-children"
      >
        <div class="gray-box"></div>
        <div
          v-if="
            !(each.layoutType == 4 && each.mindType == 1 && each.isEmpty == true)
            
          "
          class=""
          :style="
            'width: 200px;height: 125px;background:url(' +
            each.icon +
            ') no-repeat;background-size: 200px 125px;background-position:center;overflow:hidden;'
          "
        ></div>
        <div
          v-else
          class=""
          :style="
            'width: 200px;height: 125px;background:url(' +
            each.icon +
            ') no-repeat;background-size: cover;background-position:center;overflow:hidden;'
          "
        >
          <div class="empty-text">{{getString(strings.My_Mind_Blank_Mind_Map)}}</div>
        </div>
      </div>
    </div>
    <!-- <div
      v-if="showBoxWidth < scrollXWidth"
      @click="scrollRight"
      class="left-scroll-button"
    >
      <a-icon type="left" />
    </div>
    <div
      v-if="showBoxWidth < scrollXWidth"
      @click="scrollLeft"
      class="right-scroll-button"
    >
      <a-icon type="right" />
    </div> -->
  </div>
</template>
<script>
// each.icon.indexOf('left_right_layout_empty') < 0

import { mapMutations } from "vuex";
import strings from "../../common/lang/strings";
import getString from "../../common/lang/language";
import { postMindmapNewByType } from "../../common/netWork/mind_map_api";
  if ( typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0 ) {
    var {ipcRenderer} = window.require('electron')
    // var ipcRenderer = window.ipcRenderer
  }

import { postGroupNewGsheet } from "../../common/netWork/group_api"
// const {ipcRenderer} = window.require('electron')
export default {
  name: "CreatedFileScroll",
  props: {
    data: {
        type:Array,
    },
  },
  data() {
    return {
      left: 0,
      fullWidth: document.documentElement.clientWidth,
      showBoxWidth: document.documentElement.clientWidth - 215,
      clickButton: true,
      src: require('../../../static/img/mapIcon/res/mipmap-xxhdpi/left_right_layout_5.jpg'),
      src1: '../../../static/img/mapIcon/res/mipmap-xxhdpi/left_right_layout_5.jpg',
      befroeString: '../',
      urlPrefix: '../../static/img/mapIcon/res/mipmap-xxhdpi/', //地址前缀
      strings:strings
    };
  },
  created() {
  },
  mounted() {
    this.bus.$on("fullWidth", (msg) => {
      this.fullWidth = msg;
    });

  },
  methods: {    
    ...mapMutations(["refreshMyMindList"]),
    //语言包
    getString(i){
      return getString(i)
    },
    clickCreated(item, index) {
      // return;
      let parentEntityId = ""; 
      let type = item.mindType; //  导图类型
      let mindDisplayType = item.mindDisplayType; //  导图模式
      let nodeLayoutType = item.layoutType; //  导图布局
      let lineLayout = item.lineLayout; //  线条类型
      let isEmpty = item.isEmpty; //  是否是空导图
      let styleIndex = item.styleIndex; //  styleIndex
      let data = {
        parentEntityId,
        type,
        mindDisplayType,
        nodeLayoutType,
        lineLayout,
        isEmpty,
        styleIndex,
      };
      if (this.$tools.isElectron()) {
        postMindmapNewByType(
          data,
          (res) => {

            this.$emit('clickCreated',{created: true })
            ipcRenderer.send('openCalendarWindow', '/mindmap?id=' + res.mindMapId)
            this.refreshMyMindList()

          },
          (error) => {
            this.$message.error(error.desc)
          }
        );
      }
    },
    scrollLeft() {
      if (this.clickButton == false) {
        return;
      }
      this.clickButton = false;
      let count = 0;
      let timer = setInterval(() => {
        if (this.scrollXWidth - this.showBoxWidth < -this.left) {
          this.left = -(this.scrollXWidth - this.showBoxWidth);
          this.clickButton = true;
          clearInterval(timer);
        } else {
          this.left -= 13.5;
        }
        count++;
        if (count == 9) {
          clearInterval(timer);
          this.clickButton = true;
        }
      }, 50);
    },
    scrollRight() {
      if (this.clickButton == false) {
        return;
      }
      this.clickButton = false;
      let count = 0;
      let timer = setInterval(() => {
        if (0 <= this.left) {
          this.left = 0;
          this.clickButton = true;
          clearInterval(timer);
        } else {
          this.left += 13.5;
        }
        count++;
        if (count == 9) {
          clearInterval(timer);
          this.clickButton = true;
        }
      }, 50);
    },
  },
  watch: {
    fullWidth: function (val) {
      this.showBoxWidth = val - 215;
    },
  },
  computed: {
    scrollXWidth: function () {
      return this.data.length * 180;
    },
  },
};
</script>

<style lang="less" scoped>
.group-scroll-x-box {
  position: relative;
  height: 176px;
  width: 780px;
  overflow: hidden;
  overflow-x: auto;
  margin: 0 auto;
}
.left-scroll-button,
.right-scroll-button {
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #666;
  text-align: center;
  line-height: 50px;
  background-color: #fff;
  text-align: center;
  cursor: pointer;
  box-shadow: 1px 1px 2px #999;
}
.left-scroll-button {
  left: 0;
  transform: translate(0, -50%);
}
.right-scroll-button {
  right: 0;
  transform: translate(0, -50%);
}
.mind-class-content {
  position: absolute;
  /* width: 600px;
    height: 135px; */
  display: flex; 
  /* justify-content: space-between; */
  /* flex-wrap:nowrap; */
}
.modal-box-children {
  float: left;
  /* width: 120px;
  height: 105px; */
  width: 200px;
  height: 125px;
  margin: 10px 0px 10px 30px;
  border-radius: 6px;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 3px 0px #d2d2d2;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  .empty-text {
    margin: 0 auto;
    margin-top: 90px;
    font-size: 16px;
    text-align: center;
  }
  .gray-box {
    display: none;
  }
}
.modal-box-children:hover{
  box-shadow: 0 0 12px #ccc;
  .gray-box {
    display: block;
    width: 132px;
    height: 80px;
    position: relative;
    background: rgba(59, 59, 59, 0.2);
  }
}

.mind-class-content > i {
  width: 120px;
  margin-right: 20px;
  margin-left: 20px;
}

.menu-silder::-webkit-scrollbar {
  // width: 340px;
  height: 5px;
  scrollbar-arrow-color: rgba(0,0,0,0.1);
}
.menu-silder::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background: rgba(0,0,0,0.1);
}
.menu-silder::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px #ffffff;
  border-radius: 16px;
  background: #ffffff;
}
</style>